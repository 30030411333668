export function getIsoCurrentDateGtm6() {
  var dt = new Date(),
    current_date = dt.getDate(),
    current_month = dt.getMonth() + 1,
    current_year = dt.getFullYear(),
    current_hrs = dt.getHours(),
    current_mins = dt.getMinutes(),
    current_secs = dt.getSeconds(),
    current_datetime;

  current_date = current_date < 10 ? "0" + current_date : current_date;
  current_month = current_month < 10 ? "0" + current_month : current_month;
  current_hrs = current_hrs < 10 ? "0" + current_hrs : current_hrs;
  current_mins = current_mins < 10 ? "0" + current_mins : current_mins;
  current_secs = current_secs < 10 ? "0" + current_secs : current_secs;
  current_datetime =
    current_year +
    "-" +
    current_month +
    "-" +
    current_date +
    "T" +
    current_hrs +
    ":" +
    current_mins +
    ":" +
    current_secs +
    "-06:00";
  return current_datetime;
}
