<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dense
      :class="scroll ? 'scrolling' : 'elevation-0'"
    >
      <div class="">
        <v-btn text @click.stop="sidebarMenu = !sidebarMenu" color="support">
          <v-icon> mdi-menu </v-icon>
          <v-icon>
            {{ !sidebarMenu ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <img src="/img/login-logo.png" width="65" class="align-self-center" />
      <v-spacer></v-spacer>
      <div class="">
        <v-menu bottom min-width="100px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-chip
              outlined
              class="ma-2"
              color="support lighten-5"
              text-color="support"
              v-on="on"
            >
              <v-avatar left color="primary lighten-2">
                <span class="white--text">{{ nameInitial }}</span>
              </v-avatar>
              {{ firstName }}
              <v-icon right> mdi-chevron-down </v-icon>
            </v-chip>
          </template>
          <v-card class="pa-5">
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="primary lighten-1">
                  <span class="white--text text-h6">{{ nameInitial }}</span>
                </v-avatar>
                <h5 class="py-3">{{ name }}</h5>
                <h5 class="pb-3 support--text">{{ establishmentName }}</h5>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  @click="gotoSistemas()"
                  elevation="0"
                  color="secondary"
                  rounded
                  outlined
                >
                  Ir a sistemas
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn to="/logout" elevation="0" color="secondary" rounded>
                  cerrar sesión
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>
    <v-main>
      <router-view v-if="!loadingAll" />
      <Transition>
        <first-loader v-if="loadingAll" />
      </Transition>
    </v-main>
    <v-bottom-navigation
      v-model="value"
      :input-value="sidebarMenu"
      background-color="primary"
      dark
      grow
      fixed
      class="scrolling-inverse"
      app
    >
      <v-btn color="primary" class="my-btn-menu" @click="goto('NewInvoice')">
        <span>Nueva Factura</span>
        <v-icon class="pb-1">mdi-file-document-outline</v-icon>
      </v-btn>

      <v-btn color="primary" class="my-btn-menu" @click="goto('Home')">
        <span>Lista de Facturas</span>
        <v-icon class="pb-1">mdi-file-document-multiple-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { CheckSession } from "@/core/auth";
import FirstLoader from "./components/FirstLoader.vue";

export default {
  name: "App",
  components: { FirstLoader },
  data: () => ({
    value: "",
    scroll: false,
    sidebarMenu: false,
    internalLoad: true,
  }),
  methods: {
    gotoSistemas() {
      const location = window.location;
      const url = location.origin.includes("localhost")
        ? process.env.VUE_APP_LOGIN_URL
        : location.origin.replace("pos", "login");
      this.$store.dispatch("config/setBusinessUnit", null);
      window.location.href = `${url}/back?change=sysid`;
    },
    goto(url) {
      this.$router.push({ name: url }).catch(() => {});
    },
    async loadEstablishment() {
      try {
        const tokens = await CheckSession();
        this.setUserJwt({ jwt: tokens.idToken, info: tokens.payload });
        this.getEstablecimiento();
        this.getProductos();
      } catch (error) {
        this.$router.push({ name: "Logout" });
      }
      setTimeout(() => {
        this.internalLoad = false;
      }, 1000);
    },
    scrolling() {
      this.scroll =
        document.body.scrollTop > 10 || document.documentElement.scrollTop > 10;
    },
    ...mapActions({
      setUserJwt: "config/setUserJwt",
      getProductos: "products/getProductos",
      getEstablecimiento: "establishment/getEstablecimiento",
    }),
  },
  computed: {
    ...mapState({
      message: (state) => state.alerts.message,
      name: (state) => state.config.name,
      establishmentName: (state) => state.config.establishmentName,
      loadingProducts: (state) => state.products.loading,
      loadingEstablishmenParams: (state) =>
        state.establishment.loadingEstablishmenParams,
    }),
    loadingAll() {
      return (
        this.internalLoad ||
        this.loadingProducts ||
        this.loadingEstablishmenParams
      );
    },
    nameInitial() {
      return this.name.charAt(0);
    },
    firstName() {
      return this.name.split(" ")[0];
    },
  },
  watch: {
    message(newValue) {
      const { message, type } = newValue;
      if (type !== "info") {
        this.$toast.open({
          message: message,
          type: type,
          duration: 5000,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: message,
          type: type,
          duration: 5000,
          position: "top",
        });
      }
    },
    loadingAll: {
      handler() {
        this.sidebarMenu = true;
      },
      deep: true,
    },
  },
  created() {
    this.loadEstablishment();
  },
  mounted() {
    //this.loadEstablishment();
    window.onscroll = () => {
      this.scrolling();
    };
  },
};
</script>
<style scoped>
.scrolling {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
.scrolling-inverse {
  box-shadow: rgba(149, 157, 165, 0.2) 0px -20px 24px !important;
}
.my-btn-menu {
  height: inherit !important;
}
.theme--light.v-application {
  background-color: #f2f3f8 !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
