<template>
  <v-container>
    <div>
      <v-virtual-scroll
        v-if="invoices.length > 0 && !loadingInvoices"
        :items="invoices"
        min-height="50"
        max-height="56vh"
        :item-height="$vuetify.breakpoint.xs ? 225 : 180"
      >
        <template v-slot:default="{ item }">
          <v-card flat>
            <v-card-text>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-row>
                        <v-col cols="6" md="12">
                          <h3 class="primary--text">
                            {{ item.sfac_cfa_numero }}
                          </h3>
                        </v-col>
                        <v-col cols="6" md="12">
                          <v-chip
                            small
                            dark
                            :color="handleColorByStatus(item.sfac_cfa_status)"
                            :class="$vuetify.breakpoint.xs ? 'float-right' : ''"
                          >
                            {{ item.sfac_cfa_status }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="7">
                      <p class="font-weight-medium" v-if="item.sfac_cfa_fecha">
                        Fecha de emisión:
                        <span class="support--text">
                          {{ milisecondsToDate(item.sfac_cfa_fecha) || "-" }}
                        </span>
                      </p>
                      <p class="pt-3 font-weight-medium">
                        Cliente:
                        <span class="support--text">
                          {{ item.scrm_cue_nombre }}
                        </span>
                      </p>
                      <p class="pt-3 font-weight-medium">
                        Nit:
                        <span class="support--text">
                          {{ item.scrm_cue_nit }}
                        </span>
                      </p>
                    </v-col>
                    <v-col cols="12" md="2" align-self="center">
                      <h3>
                        Total:
                        <span class="support--text">
                          {{ formatCurrencyGTTotal(item.sfac_cfa_valor_total) }}
                        </span>
                      </h3>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                  <v-btn icon @click="viewDetails(item)">
                    <v-icon color="my_yellow"> mdi-arrow-right </v-icon>
                  </v-btn>
                  <v-btn icon @click="viewEmailModel(item)">
                    <v-icon class=""> mdi-email-send-outline </v-icon>
                  </v-btn>
                  <v-btn icon @click="rePrint(item)">
                    <v-icon class=""> mdi-printer-check </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card-text>
          </v-card>
        </template>
      </v-virtual-scroll>

      <v-row v-else-if="invoices.length === 0 && !loadingInvoices">
        <v-col cols="12">
          <v-card flat class="py-15">
            <v-row class="pt-3 py-md-15 px-md-6" no-gutters align="center">
              <v-col
                cols="12"
                class="p-0 grey--text text--darken-3"
                align="center"
                justify="center"
              >
                <h2 class="primary--text">No existen Facturas</h2>
                <small class="font-weight-medium">
                  Para este día no existen facturas.
                </small>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-card flat class="py-15">
            <v-row class="pt-3 py-md-15 px-md-6" no-gutters align="center">
              <v-col
                cols="12"
                class="p-0 m-0 pt-15"
                align="center"
                justify="center"
              >
                <loader />
              </v-col>
              <v-col
                cols="12"
                class="p-0 grey--text text--darken-3"
                align="center"
                justify="center"
              >
                <h2 class="primary--text">¡Un momento por favor!</h2>
                <small class="font-weight-medium">
                  Estamos cargando tus facturas.
                </small>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <br />
      <v-row v-if="invoices.length > 0 && !loadingInvoices">
        <v-col cols="12">
          <v-card flat class="py-175">
            <v-row class="pt-3 py-md-55 px-md-6" no-gutters align="center">
              <v-card flat>
                <v-card-text>
                  <v-row class="">
                    <v-col cols="12" sm="12" md="6">
                      <h2 class="font-weight-bold d-flex align-end">
                        <v-icon color="support" large left>
                          mdi-calculator
                        </v-icon>
                        <span class="support--text">
                          Total: {{ formatCurrencyGTTotal(totalPerDay) }}
                        </span>
                      </h2>
                    </v-col>
                    <v-col cols="12" sm="12" md="6"> </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <h2 class="font-weight-bold d-flex align-end">
                        <v-icon color="support" large left>
                          mdi-briefcase
                        </v-icon>
                        <span class="support--text">
                          Total facturas: {{ totalLength }}
                        </span>
                      </h2>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <send-email
      v-if="dialogSendEmail"
      @close="dialogSendEmail = false"
      :autorizacion="send_sfac_cfa_numero"
    >
    </send-email>
  </v-container>
</template>

<script>
import SendEmail from "./SendEmail";
import { mapState, mapActions } from "vuex";
import { formatCurrencyGT } from "@/core/utils";
import {
  generateBodyRePrint,
  convertIntObj,
  logoPollo64,
  getTipoDocumentoName,
  chapincito64,
  logoCafeQuetzalBistro,
  defaultLogo64,
} from "../utils";
import { milisecondsToDate } from "@/core/utils";
import Loader from "./loader";
export default {
  components: { Loader, SendEmail },
  data() {
    return {
      totalPerDay: 0,
      totalLength: 0,
      dataToPrint: [],
      itemSelect: null,
      dialogSendEmail: false,
      send_sfac_cfa_numero: "",
      logo: {
        logoX: 23,
        logoY: 5,
        logoAncho: 10,
        logoAlto: 10,
      },
      font: {
        fontNombreComercial: 7,
        fontRazonSocial: 7,
        fontDatosEmpresa: 7,
        fontDatosFactura: 7,
        fontDatosCliente: 7,
        fontDetalle: 7,
      },
      client: {
        nit: "CF",
        nombre: "Consumidor Final",
        direccion: "Ciudad",
        correo: "correo@correo.com",
        vendedor: "Directos",
        codigo: "",
      },
      chapincitoIds: ["1298", "1856"],
      pollolenaIds: [
        "898",
        "901",
        "902",
        "903",
        "904",
        "905",
        "906",
        "907",
        "908",
        "909",
        "910",
        "911",
        "912",
        "913",
        "914",
        "915",
        "916",
        "917",
        "918",
        "919",
        "920",
        "921",
        "922",
        "923",
        "924",
        "925",
        "1099",
        "1100",
        "2227",
      ],
    };
  },
  methods: {
    milisecondsToDate(value) {
      return milisecondsToDate(value);
    },
    formatCurrencyGTTotal(value) {
      return formatCurrencyGT(value);
    },
    viewEmailModel(item) {
      this.dialogSendEmail = true;
      this.send_sfac_cfa_numero = item.sfac_cfa_numero;
    },
    viewDetails(item) {
      this.$router.push({
        name: "InvoiceDetails",
        params: { id: item.sfac_cfa_id },
      });
    },
    async rePrint(item) {
      const query = {
        cfaId: +item.sfac_cfa_id,
      };
      this.itemSelect = item;
      this.client.nit = item.scrm_cue_nit;
      this.client.nombre = item.scrm_cue_nombre;
      this.client.direccion = item.scrm_cue_factura_direccion;
      this.client.correo = item.scrm_cue_email;
      this.client.codigo = item.scrm_cue_codigo;
      await this.getFactura({ query });
      await this.getFacturaDetalle({ query });
    },
    handleClick(value) {
      this.$router.push({
        name: "InvoiceDetails",
        params: { id: value.sfac_cfa_id },
      });
    },
    ...mapActions({
      getFactura: "invoices/getFactura",
      getFacturaDetalle: "invoices/getFacturaDetalle",
    }),
    handleColorByStatus(status) {
      let color = "primary";
      switch (status.toUpperCase()) {
        case "PAGADA":
          color = "green lighten-2";
          break;
        case "GRABADA":
          color = "primary lighten-2";
          break;
        case "ANULADA":
          color = "red lighten-2";
          break;
        default:
          break;
      }
      return color;
    },
    calculateTotalPerDay(val) {
      this.totalPerDay = 0;
      this.totalLength = val.length;
      val.forEach((element) => {
        this.totalPerDay += +element.sfac_cfa_valor_total;
      });
    },
    getTotalIva(products) {
      let ivaTotal = 0;
      products.forEach((data) => {
        ivaTotal += data.iva;
      });
      return ivaTotal;
    },
    getTempLogo() {
      if (this.chapincitoIds.includes(this.establishment)) {
        return chapincito64;
      }

      if (this.pollolenaIds.includes(this.establishment)) {
        return logoPollo64;
      }

      if (this.establishment === "2533") {
        return logoCafeQuetzalBistro;
      }
      return defaultLogo64;
    },
    async rePrintInvoice(val) {
      /*console.log("this.establishment");
      console.log(this.establishment);
      console.log(this.establishment === "1856");*/
      let dte = this.itemSelect;
      let dataItems = await generateBodyRePrint(val);
      const data = {
        ...convertIntObj(this.logo),
        ...convertIntObj(this.font),
        color: this.color,
        logo: this.getTempLogo(),
        client: { ...this.client },
        monedaName: "Quetzales",
        usuario: {
          nombre: this.userLoginName,
        },
        establishment: {
          razon: this.establishmentParams.sadm_est_razon,
          telefono: this.establishmentParams.sadm_est_telefono,
          regimen: "Régimen FEL",
          nit: this.establishmentParams.sadm_est_nit,
        },
        factura: {
          tipoDocumento: getTipoDocumentoName("FACT"),
          noFactura: this.itemSelect.sfac_cfa_numero,
          autorizacion: this.itemSelect.sfac_cfa_face_firma_electronica,
          serie: dte.sfac_cfa_face_serie,
          folio: dte.sfac_cfa_face_folio,
          fechaEmision: new Date(
            dte.sfac_cfa_face_fecha_emision
          ).toLocaleString(),
          fechaCertificacion: new Date(
            dte.sfac_cfa_face_fecha_certificado
          ).toLocaleString(),
          fechaVencimiento: "08/19/2022 19:13:20",
          Resolucion:
            this.establishmentParams.sadm_est_sat_fecha_resolucion_isr,
          retenedorIva:
            this.establishmentParams.sadm_est_retenedor_iva === "1"
              ? "AGENTE RETENEDOR DE IVA"
              : "",
          textoRegimen: this.establishmentParams.sadm_est_sat_texto_regimen
            ? this.establishmentParams.sadm_est_sat_texto_regimen
            : "",
          Certificador: "MEGAPRINT, S.A. NIT: 5051023-1",
          moneda: "GTQ",
          produccion: true,
          tipoVenta: "Local",
          total: this.itemSelect.sfac_cfa_valor_total,
          iva: this.getTotalIva(dataItems),
          descuento: 0,
          retenIva: true,
          retenIsr: true,
          aplicaIva: true,
          retencionIsr: 2.23,
          retencionIva: 5.45,
          totalLetras:
            "Un mil novecientos sesenta y seis Quetzales con\n" + "87/100",
          otrosDatos:
            "La receta que os presento hoy no es la clásica que\n" +
            "inventó Doña Plácida de Larrea en mayo de 1723.\n" +
            "Os sorprenderá que tenga fecha exacta… resulta\n" +
            "que hay registro escrito de la receta y de quién la\n" +
            "cocinó. En una carta a una amiga Doña Plácida\n" +
            "comentaba cómo había guisado la merluza, en\n" +
            "salsa con abundantes perejil y adornándola con\n" +
            "espárragos de Tudela, chirlas y una docena de\n" +
            "gordos cangrejos pescados en aguas del Ibaizabal.",
        },
        sucursal: {
          nombre: this.establishmentParams.sadm_est_nombre,
          direccion: this.establishmentParams.sadm_est_direccion,
          direccion_l2: this.establishmentParams.sadm_est_direccion_linea2,
          direccion_l3: this.establishmentParams.sadm_est_direccion_linea3,
          correo: this.establishmentParams.sadm_est_email,
        },
        ffp: {
          ffpFontEncabezado: 7,
          ffpFontProductos: 7,
          ffpInterlineado: 3,
          ffpXTelefono: 0,
          ffpXVendedor: 0,
          ffpXUsuario: 0,
          ffpXTipoPago: 0,
          ffpXCodigoCliente: 0,
          ffpXCodigoClienteAlterno: 0,
          ffpXPrecioUnitario: 0,
          ffpXTituloTotalLetrasMn: 1,
        },
        formatoPdf: "6",
        items: dataItems,
      };
      this.dataToPrint = data;
      this.$router.push({
        name: "ImprimirFactura",
        params: {
          dataNewInvoice: this.dataToPrint,
        },
      });
    },
  },
  watch: {
    invoices(val) {
      this.calculateTotalPerDay(val);
    },
    invoice(val) {
      this.itemSelect = val;
    },
    details(val) {
      this.rePrintInvoice(val);
    },
  },
  computed: {
    ...mapState({
      invoices: (state) => state.invoices.invoices,
      invoice: (state) => state.invoices.invoice,
      details: (state) => state.invoices.detalle,
      productos: (state) => state.products.products,
      establishmentParams: (state) => state.establishment.establishmentParams,
      loadingInvoices: (state) => state.invoices.loading,
      establishment: (state) => state.config.establishment,
    }),
  },
};
</script>
