<template>
  <div class="flex justify-center align-center fill-height white">
    <v-row class="fill-height" align="center">
      <v-col cols="12" class="" align="center">
        <div class="">
          <loader />
        </div>
        <div class="pt-0 mt-0">
          <h2 class="primary--text pt-0 mt-0">¡Un momento por favor!</h2>
          <small class="font-weight-medium">
            Tu sistema estará listo en breve.
          </small>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loader from "./loader.vue";

export default {
  name: "FirstLoader",
  components: { Loader },
};
</script>

<style scoped></style>
