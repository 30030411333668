const state = () => ({
  lineasProducto: [],
  categorias: [],
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  getLineasProducto({ dispatch, commit }, products) {
    commit("setLoading", true);

    let lineasProducto = products.slice().map((producto) => ({
      sinv_lpr_id: producto.sinv_lpr_id,
      sinv_lpr_descripcion: producto.sinv_lpr_descripcion,
      sinv_lpr_pos_orden: producto.sinv_lpr_pos_orden,
    }));
    //se elimina el duplicado
    lineasProducto = lineasProducto.filter(
      (valorActual, indiceActual, arreglo) => {
        return (
          arreglo.findIndex(
            (valorDelArreglo) =>
              JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)
          ) === indiceActual
        );
      }
    );

    let lineasProductoConOrden = lineasProducto
      .slice()
      .filter((categoria) => categoria.sinv_lpr_pos_orden !== null)
      .sort((a, b) => {
        return a.sinv_lpr_pos_orden - b.sinv_lpr_pos_orden;
      });

    let lineasProductoSinOrden = lineasProducto
      .slice()
      .filter((categoria) => categoria.sinv_lpr_pos_orden === null)
      .sort((a, b) => {
        return a.sinv_lpr_pos_orden - b.sinv_lpr_pos_orden;
      });

    lineasProducto = [].concat(lineasProductoConOrden, lineasProductoSinOrden);

    dispatch("getCategories", products);

    commit("setLineasProducto", lineasProducto);
    commit("setLoading", false);
  },
  getCategories({ commit }, products) {
    const categoriasPorLineaProducto = {};

    products.forEach((producto) => {
      const { sinv_lpr_id, sinv_cat_id, sinv_cat_descripcion } = producto;

      if (!categoriasPorLineaProducto[sinv_lpr_id]) {
        categoriasPorLineaProducto[sinv_lpr_id] = {
          sinv_lpr_id,
          categorias: [],
        };
      }

      const lineaProducto = categoriasPorLineaProducto[sinv_lpr_id];
      const { categorias } = lineaProducto;
      const existeCategoria = categorias.some(
        (cat) => cat.sinv_cat_id === sinv_cat_id
      );

      if (!existeCategoria) {
        categorias.push({ sinv_cat_id, sinv_cat_descripcion });
      }
    });
    const resultadoFinal = Object.values(categoriasPorLineaProducto);
    commit("setCategorias", resultadoFinal);
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLineasProducto(state, lineasProducto) {
    state.lineasProducto = lineasProducto;
  },
  setCategorias(state, categorias) {
    state.categorias = categorias;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
