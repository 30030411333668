import { getIsoCurrentDateGtm6 } from "../utils";

export function generateBodyFelPDF(detalle) {
  let items = detalle.map((producto) => {
    return {
      bienOServicio: producto.sinv_pro_tipo_producto === "Servicio" ? "S" : "B",
      descripcion: producto.sinv_pro_descripcion,
      cantidad: +producto.cantidad,
      precio: +producto.sinv_pro_precio,
      total: +producto.cantidad * +producto.sinv_pro_precio,
      iva: +producto.sinv_pro_precio * 0.12,
      descuento: 0,
      codigoProducto: producto.sinv_pro_codigo,
      unidadMedida: producto.sinv_ume_siglas,
    };
  });
  return items;
}

export function generateBodyRePrint(detalle) {
  return detalle.map((producto) => {
    return {
      descripcion: producto.sinv_pro_descripcion,
      cantidad: +producto.sfac_fac_cantidad,
      precio: +producto.sinv_pro_precio,
      total:
        +producto.sfac_fac_cantidad * +producto.sinv_pro_precio -
        +producto.sfac_fac_importe_descuento,
      iva: +producto.sinv_pro_precio * 0.12,
      descuento: 0,
      codigoProducto: producto.sinv_pro_codigo,
      unidadMedida: producto.sinv_ume_siglas
        ? producto.sinv_ume_siglas
        : "unis",
    };
  });
}

export function generateHeaderFelPDF(cliente, detalleF) {
  let params = {
    tipoDocumento: "FACT",
    documento: {
      codigoCliente: "01",
      datosCliente: {
        nit: cliente.nit,
        nombre: cliente.nombre,
        correo: cliente.correo,
        direccionCliente: {
          direccion: "",
        },
      },
      fechaEmision: "",
      moneda: "",
      valorTotal: 1.0,
      aplicaIva: "SI",
      tipoVenta: "LOCAL",
      detalle: detalleF.map((producto) => {
        return {
          codigo: producto.codigoAlterno,
          descripcion: producto.descripcion,
          bienServicio: producto.tipo[0],
          unidadMedida: producto.unidadMedida,
          cantidad: +producto.cantidad,
          precio: +producto.precio,
          importeBruto: producto.cantidad * producto.precio,
          descuento: +producto.descuento,
          total: producto.cantidad * producto.precio - producto.descuento,
        };
      }),
    },
  };
  return params;
}

export function generateParamsCertificarPDF(cliente, detalleF) {
  let registro = getIsoCurrentDateGtm6();
  let params = {
    tipoDocumento: "FACT",
    documento: {
      codigoCliente: cliente.nit,
      datosCliente: {
        nit: cliente.nit,
        nombre: cliente.nombre,
        correo: cliente.correo,
        direccionCliente: {
          direccion: cliente.direccion,
        },
      },
      fechaEmision: registro,
      moneda: "GTQ",
      valorTotal: total(detalleF),
      aplicaIva: "SI",
      tipoVenta: "LOCAL",
      detalle: makeDetail(detalleF),
    },
  };

  function total(detalleF) {
    return detalleF
      .slice()
      .reduce(
        (partialSum, { subtotal }) =>
          parseFloat(partialSum) + parseFloat(subtotal),
        0
      );
  }

  function makeDetail(detalleF) {
    return detalleF.slice().map((producto) => {
      return {
        codigoProducto: producto.sinv_pro_codigo_alterno,
        descripcion: producto.sinv_pro_descripcion,
        bienServicio:
          producto.sinv_pro_tipo_producto === "Servicio" ? "S" : "B",
        unidadMedida: producto.sinv_ume_siglas,
        cantidad: +producto.cantidad,
        precio: +producto.sinv_pro_precio,
        importeBruto: +producto.cantidad * +producto.sinv_pro_precio,
        descuento: 0,
        total: +producto.cantidad * +producto.sinv_pro_precio,
      };
    });
  }
  return params;
}
