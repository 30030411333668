<template>
  <v-card flat>
    <div id="imprimiendo_container">
      <div class="imprimiendo">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div>imprimiendo</div>
      </div>
    </div>
    <v-card-text id="vistaImpresionContainer2">
      <v-row id="vistaImpresionContainer">
        <div id="vista_impresion" ref="content">
          <v-col cols="12" id="vista_margin">
            <div class="datos_emisor">
              <img v-bind:src="'data:image/jpeg;base64,' + data.logo" />
              <p>{{ data.establishment ? data.establishment.razon : "" }}</p>
              <p>{{ data.sucursal ? data.sucursal.nombre : "" }}</p>
              <p>{{ data.sucursal ? data.sucursal.direccion : "" }}</p>
              <p>{{ data.sucursal ? data.sucursal.direccion_l2 : "" }}</p>
              <p>{{ data.sucursal ? data.sucursal.direccion_l3 : "" }}</p>
              <p>NIT: {{ data.establishment ? data.establishment.nit : "" }}</p>
            </div>
            <div class="datos_factura">
              <p>
                <b>{{ data.factura ? data.establishment.regimen : "" }}</b>
              </p>
              <p></p>
              <p>
                <b>Documento Tributario Electrónico</b>
              </p>
              <p>
                <b>{{ data.factura ? data.factura.tipoDocumento : "" }}</b>
              </p>
              <p><b>No. Autorización:</b></p>
              <p id="NoAutorizacion">
                {{ data.factura ? data.factura.autorizacion : "" }}
              </p>
              <p><b>Serie:</b> {{ data.factura ? data.factura.serie : "" }}</p>
              <p>
                <b
                  >Número de DTE:
                  {{ data.factura ? data.factura.folio : "" }}</b
                >
              </p>
              <p><b>Fecha y Hora Emisión</b></p>
              <p>{{ data.factura ? data.factura.fechaEmision : "" }}</p>
              <p><b>Fecha y Hora Certificación:</b></p>
              <p>{{ data.factura ? data.factura.fechaCertificacion : "" }}</p>
              <p><b>Moneda</b></p>
              <p>{{ invoice.moneda }}</p>
            </div>
            <div class="datos_cliente">
              <p><b>NIT:</b></p>
              <p>{{ data.client ? data.client.nit : "" }}</p>
              <p><b>Nombre</b></p>
              <p>{{ data.client ? data.client.nombre : "" }}</p>
              <p><b>Dirección</b></p>
              <p>{{ data.client ? data.client.direccion : "" }}</p>
            </div>
            <div class="datos_producto">
              <div class="row">
                <div
                  class="col col-md-9"
                  cols="12"
                  sm="6"
                  md="8"
                  style="float: left"
                >
                  <p><b>Descripción</b></p>
                </div>
                <div class="col col-md-3" cols="6" md="4" style="float: right">
                  <p><b>Valor</b></p>
                </div>
              </div>
              <div v-for="(producto, index) in data.items" :key="index">
                <div class="row">
                  <div class="col col col-md-9">
                    <p :class="[`${getLetterSize(producto.descripcion)}`]">
                      {{ producto.descripcion }}
                    </p>
                    <p>
                      {{ producto.cantidad }}
                      {{ producto.unidadMedida.toUpperCase() }}
                    </p>
                  </div>
                  <div class="col col-md-3" style="float: right">
                    <p class="unidades">
                      <span class="alinear_derecha"
                        >Q {{ producto.total }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p><b>Total</b></p>
                </div>
                <div class="col">
                  <p>
                    <b
                      ><span class="alinear_derecha"
                        >Q {{ data.factura ? data.factura.total : "" }}</span
                      ></b
                    >
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p><b>Total Iva</b></p>
                </div>
                <div class="col">
                  <p>
                    <b
                      ><span class="alinear_derecha"
                        >Q {{ data.factura ? data.factura.iva : "" }}</span
                      >
                    </b>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p id="TotalLetras">
                    {{ textosTotal ? textosTotal.totalLetras : "" }}
                  </p>
                </div>
              </div>
            </div>
            <div class="datosCertificacionFactura">
              <p>
                <b>{{ data.factura ? data.factura.textoRegimen : "" }}</b>
              </p>
              <p>
                <b>{{ data.factura ? data.factura.retenedorIva : "" }}</b>
              </p>
              <p>
                <b>{{
                  data.factura
                    ? data.factura.Resolucion
                      ? "No retener, resolución"
                      : ""
                    : ""
                }}</b>
              </p>
              <p>
                <b>{{ data.factura ? data.factura.Resolucion : "" }}</b>
              </p>
              <p>Certificador</p>
              <p>
                <b>{{ data.factura ? data.factura.Certificador : "" }}</b>
              </p>
              <p>
                <b>
                  {{
                    data.factura
                      ? data.factura.vendedor !== ""
                        ? "Vendedor:"
                        : ""
                      : ""
                  }}
                </b>
              </p>
              <p>
                {{
                  data.factura
                    ? data.factura.vendedor !== ""
                      ? data.factura.vendedor
                      : ""
                    : ""
                }}
              </p>
              <img
                id="qr_factura"
                v-bind:src="'data:image/png;base64,' + qrFactura"
              />
            </div>
            <div class="otrosDatos">
              <p>
                {{ data.factura ? data.factura.textoFactura1 : "" }}
              </p>
            </div>
          </v-col>
        </div>
      </v-row>
      <v-row>
        <div id="botones" ref="content">
          <v-col cols="6">
            <v-btn
              block
              outlined
              @click="print"
              color="primary"
              class="py-5 my-10 print_btn"
              id="foo"
            >
              <v-icon class="pr-4"> mdi-printer-outline</v-icon>
              imprimir
            </v-btn>
            <!--<button @click="generateReport">Download Pdf</button>-->
            <v-btn
              block
              outlined
              @click="generateReport"
              color="primary"
              class="py-5 my-10 print_btn"
            >
              <v-icon class="pr-4"> mdi-printer-outline</v-icon>
              Generar pdf
            </v-btn>
          </v-col>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { generateQR } from "../utils";
import { convertirTotalALetras } from "../utils";
import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";
import html2pdf from "html2pdf.js";
import { uploadFile } from "@/core/auth/";
Vue.use(VueHtmlToPaper);
export default {
  data() {
    return {
      invoice: {
        ivaIncluido: true,
        fechaEmision: "03/03/2022 14:00",
        numeroFactura: "3CF4A201-2763016640",
        serieFactura: "96DFC03D",
        numeroAutorizacion: "3CF4A201-2763016640",
        moneda: "GTQ",
        total: 82.0,
      },
      emisor: {
        est_nit: "8525151-5",
        est_email: "COMIDA RAPIDA, S.A",
        est_sat_texto_regimen: "Régimen FEL",
        est_telefono: "COMIDA RAPIDA, S.A",
        sat_texto_regimen: "COMIDA RAPIDA, S.A",
        est_logo_path_ticket: "COMIDA RAPIDA, S.A",
        est_logo_imagen_x_ticket: "COMIDA RAPIDA, S.A",
        est_logo_imagen_y_ticket: "COMIDA RAPIDA, S.A",
        est_logo_imagen_alto_ticket: "COMIDA RAPIDA, S.A",
        est_logo_imagen_ancho_ticket: "COMIDA RAPIDA, S.A",
        adesd_ve_factura_texto: "Documento Tributario Electrónico",
        adesd_ve_factura_texto2: "Factura",
      },
      headers: [
        { text: "Código", value: "codigoProducto" },
        { text: "Descripción", value: "descripcion" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio", value: "precioUnitario", align: "right" },
        { text: "Descuento", value: "descuento", align: "right" },
        { text: "SubTotal", value: "subtotal", align: "right" },
      ],
      data: [],
      pdfString: "",
      qrFactura: "",
      totalEnLetras: "",
      textosTotal: {
        total: "",
        TotalIva: "",
      },
    };
  },
  computed: {
    ...mapState({
      userLoginName: (state) => state.config.name,
    }),
    nitCliente() {
      return this.client.nit;
    },
  },
  methods: {
    ...mapActions({
      certificarDocumento: "invoices/certificarDocumento",
    }),
    getLetterSize(text) {
      switch (true) {
        case text.length > 25 && text.length < 50:
          return "descripction-md";
        case text.length > 49:
          return "descripction-lg";
        default:
          return "descripction-sx";
      }
    },
    async saveToS3(name, file) {
      file = file.split(",")[1];
      try {
        file = Buffer.from(file, "base64");
        const props = `${process.env.VUE_APP_ENV}/POS`;
        uploadFile(`${name}.pdf`, props, file, process.env.VUE_APP_BUCKET);
      } catch (error) {
        console.log(error);
      }
    },
    async print() {
      const htmlpdf = await this.getHtmlPdf();
      this.saveToS3(this.data.factura.noFactura, htmlpdf);
    },
    getHtmlPdf() {
      const options = {
        styles: [
          "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
          "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
          "https://fonts.googleapis.com/css?family=Bungee+Inline",
          "js/custom.css",
        ],
      };
      this.$htmlToPaper("vistaImpresionContainer", options);
      return new Promise((resolve) => {
        html2pdf()
          .set({
            margin: 0,
            filename: "factura.pdf",
            image: { type: "png", quality: 1 },
            html2canvas: { scale: 1 },
            jsPDF: {
              unit: "px",
              format: [
                document.getElementById("vista_margin").offsetWidth,
                document.getElementById("vista_margin").offsetHeight,
              ],
            },
          })
          .from(document.getElementById("vistaImpresionContainer"))
          .toPdf()
          .output("datauristring")
          .then(function (pdfAsString) {
            this.pdfString = pdfAsString;
            resolve(pdfAsString);
          });
      });
    },
    generateReport() {
      html2pdf(document.getElementById("vistaImpresionContainer"), {
        margin: 0,
        filename: "factura.pdf",
        image: { type: "png", quality: 0.7 },
        html2canvas: { scale: 1 },
        jsPDF: {
          unit: "px",
          format: [
            document.getElementById("vista_margin").offsetWidth,
            document.getElementById("vista_margin").offsetHeight,
          ],
        },
      });
    },
    async callGenerateQR() {
      this.qrFactura = await generateQR(this.data);
    },
    async callTotalALetras() {
      this.textosTotal = await convertirTotalALetras(this.data);
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    if (!this.$route.params.dataNewInvoice) {
      this.$router.push({ name: "Home" });
    } else {
      this.data = Object.assign({}, this.$route.params.dataNewInvoice);
      this.callGenerateQR().then(() => {
        this.callTotalALetras().then(() => {
          this.print();
        });
      });
      document.getElementById("imprimiendo_container").style.display = "none";
    }
  },
};
</script>
