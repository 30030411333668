export function formatCurrencyGT(value) {
  let moneda = localStorage.getItem("currency");

  if (moneda) {
    return new Intl.NumberFormat("es-GT", {
      style: "currency",
      currency: moneda,
      currencyDisplay: "narrowSymbol",
    }).format(value);
  }

  return new Intl.NumberFormat("es-GT", {
    style: "currency",
    currency: "GTQ",
    currencyDisplay: "narrowSymbol",
  }).format(value);
}

export function getTipoActividadItems() {
  return [
    { id: "TRA", value: "Trabajo" },
    { id: "SUS", value: "Suspensión" },
    { id: "VAC", value: "Vacaciones" },
    { id: "LEG", value: "Legal" },
    { id: "FAL", value: "Falta" },
    { id: "SEP", value: "Septimos" },
    { id: "ASU", value: "Asuetos" },
  ];
}

export const getTipoActidadById = (paramSearch) => {
  const tipoActividades = getTipoActividadItems();
  let result = "--";
  let tipoActividadValue = tipoActividades.find(
    (tipoAct) => tipoAct.id === paramSearch
  ).value;
  if (tipoActividadValue) {
    result = tipoActividadValue;
  }
  return result;
};
