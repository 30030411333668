import Vue from "vue";
import VueRouter from "vue-router";
import { GetToken, SetTokens } from "@/core/auth";
import { checkToken } from "@/core/helpers";
import store from "../store/";
import NewInvoice from "../views/NewInvoice.vue";
import InvoiceList from "../views/InvoiceList.vue";
import InvoiceDetails from "../views/InvoiceDetails.vue";
import ImpresionFactura from "../views/ImpresionFactura.vue";
import pdfFactura from "../views/pdfFactura.vue";

Vue.use(VueRouter);
async function requireAuth(to, from, next) {
  try {
    const token = GetToken();
    if (checkToken(token)) next();
    else throw "Token invalido";
  } catch (error) {
    localStorage.clear();
    window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
  }
}

async function setTokens(to) {
  SetTokens(to.params);
  const jsonPayload = window.atob(to.params.estData);
  store.dispatch("config/setEst", JSON.parse(jsonPayload), to.params.idToken);
  router.push({ name: "Home" });
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: InvoiceList,
    beforeEnter: requireAuth,
  },
  {
    path: "/nuevaFactura",
    name: "NewInvoice",
    component: NewInvoice,
    beforeEnter: requireAuth,
  },
  {
    path: "/facturas",
    name: "InvoiceList",
    component: InvoiceList,
    beforeEnter: requireAuth,
  },
  {
    path: "/facturas/:id",
    name: "InvoiceDetails",
    component: InvoiceDetails,
    beforeEnter: requireAuth,
  },
  {
    path: "/impresionfactura",
    name: "ImprimirFactura",
    component: ImpresionFactura,
    beforeEnter: requireAuth,
  },
  {
    path: "/pdfFacturas/:id",
    name: "PdfFactura",
    component: pdfFactura,
    beforeEnter: requireAuth,
  },
  {
    path: "/oauth/:user/:accessToken/:refreshToken/:idToken/:estData",
    name: "OAuth",
    beforeEnter: setTokens,
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter() {
      localStorage.clear();
      window.location.href = `${process.env.VUE_APP_LOGIN_URL}/logout`;
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
