<template>
  <v-container class="py-8">
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <v-row class="">
              <v-col cols="12" sm="12" md="6">
                <h2 class="font-weight-bold d-flex align-end">
                  <v-icon color="support" large left>
                    mdi-file-document-outline
                  </v-icon>
                  <span class="primary--text pb-1 mt-3">Lista de Facturas</span>
                </h2>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat class="mt-5">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-overflow
            min-width="auto"
            nudge-right="40"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  :class="{ 'on-hover': hover }"
                  :color="hover ? '#DDD9D9' : 'default'"
                  elevation="0"
                >
                  <v-card outlined class="pb-2 pt-4">
                    <v-row v-on="on" v-bind="attrs">
                      <v-col cols="12" sm="12" align="center">
                        <v-icon color="my_yellow" large>mdi-calendar</v-icon>
                        <h5
                          class="font-weight-medium support--text text--darken-2 pb-1"
                        >
                          Filtrar por día
                        </h5>
                        <h4 class="text-center primary--text">
                          <span>
                            {{ getMonth }}
                          </span>
                        </h4>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-hover>
            </template>
            <v-list class="pa-0">
              <v-list-item class="pa-0">
                <v-date-picker
                  v-model="date"
                  locale="es"
                  @input="menu = false"
                  color="my_yellow"
                  header-color="primary"
                >
                </v-date-picker>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card>
        <div class="mt-5">
          <Details />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Details from "../components/Details.vue";
import { now } from "@/core/utils";
import { mapState, mapActions } from "vuex";

export default {
  name: "InvoiceList",
  components: { Details },
  data: () => ({
    menu: false,
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    date: now(),
  }),
  computed: {
    getMonth: function () {
      let dateSplit = this.date.split("-");
      return `${dateSplit[2]} ${
        this.months[Number.parseInt(dateSplit[1]) - 1]
      } - ${dateSplit[0]}`;
    },
    ...mapState({
      loading: (state) => state.invoices.loading,
      productos: (state) => state.products.products,
    }),
  },
  methods: {
    getDate() {
      let date = new Date();
      let year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },
    getInvoices(date) {
      const initDate = new Date(date);
      // const lastDate = new Date(
      //   initDate.getFullYear(),
      //   initDate.getMonth() + 1,
      //   0
      // );
      const query = {
        fechaInicial: initDate.getTime(),
        fechaFinal: initDate.getTime(),
      };
      this.getFacturas({ query });
    },
    ...mapActions({
      getFacturas: "invoices/getFacturas",
      getProductos: "products/getProductos",
    }),
  },
  watch: {
    date(val) {
      this.getInvoices(val);
    },
  },
  mounted() {
    setTimeout(() => {
      this.getInvoices(this.date);
    }, 1500);
  },
};
</script>
