import gql from "graphql-tag";

export const getS3Url = gql`
  query getS3Url(
    $uuidCertificacion: ID!
    $archivo: TipoArchivo
    $plantilla: String
  ) {
    getS3Url(
      uuidCertificacion: $uuidCertificacion
      archivo: $archivo
      plantilla: $plantilla
    )
  }
`;

export const enviarFactura = gql`
  mutation sendEmailDte(
    $autorizacion: String!
    $body: String!
    $subject: String!
    $email: String!
  ) {
    sendEmailDte(
      autorizacion: $autorizacion
      body: $body
      subject: $subject
      email: $email
    )
  }
`;
