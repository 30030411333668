<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <v-row class="">
              <v-col cols="12" sm="12" md="6">
                <h2 class="font-weight-bold d-flex align-end">
                  <v-btn icon to="/facturas" class="mr-2">
                    <v-icon large> mdi-arrow-left</v-icon>
                  </v-btn>
                  <span class="primary--text pb-1 mt-3">
                    Información de Factura
                  </span>
                </h2>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <DetailsInvoice />
    </div>
  </v-container>
</template>

<script>
import DetailsInvoice from "../components/DetailsInvoice.vue";
export default {
  name: "InvoiceList",
  components: { DetailsInvoice },
};
</script>
