export function checkToken(token) {
  try {
    const dataToken = parseJwt(token);
    return dataToken.exp * 1000 >= Date.now();
  } catch (error) {
    return false;
  }
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function checkTokenAdmin(token) {
  try {
    const dataToken = parseJwt(token);
    return (
      dataToken.exp * 1000 >= Date.now() &&
      dataToken["cognito:groups"].includes("Administrador")
    );
  } catch (error) {
    return false;
  }
}
