<template>
  <div :class="mini ? 'loader-container-mini' : 'loader-container'">
    <object
      :class="mini ? 'object-mini' : 'object'"
      type="image/svg+xml"
      data="img/loader.svg"
    ></object>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: {
    mini: Boolean,
  },
};
</script>

<style scoped>
.loader-container {
  height: 110px;
}
.object {
  width: 92px !important;
}

.loader-container-mini {
  height: 40px;
}
.object-mini {
  width: 20px !important;
}
</style>
