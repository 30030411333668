import gql from "graphql-tag";

export const getEstablecimiento = gql`
  query {
    getEstablecimiento {
      sadm_est_razon
      sadm_est_nombre
      sadm_est_siglas
      sadm_est_direccion
      sadm_est_direccion_linea2
      sadm_est_direccion_linea3
      sadm_est_email
      sadm_est_telefono
      sadm_est_sat_texto_regimen
      sadm_est_logo_path_ticket
      sadm_est_logo_imagen_x_ticket
      sadm_est_logo_imagen_y_ticket
      sadm_est_logo_imagen_alto_ticket
      sadm_est_logo_imagen_ancho_ticket
      sadm_adesd_ve_factura_texto
      sadm_adesd_ve_factura_texto2
      sadm_est_sat_fecha_resolucion_isr
      sadm_est_retenedor_iva
      sadm_est_nit
    }
  }
`;
