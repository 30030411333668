import { objectToBase64 } from "@/core/utils";

const state = () => ({
  toggleMini: false,
  businessUnit: localStorage.getItem("businessUnit"),
  company: localStorage.getItem("company"),
  establishment: localStorage.getItem("establishment"),
  establishmentName: localStorage.getItem("establishmentName"),
  name: localStorage.getItem("name"),
  estIdCentral:
    localStorage.getItem("estIdCentral") === "null"
      ? null
      : localStorage.getItem("estIdCentral"),
  user: "",
  empresas: [],
  establecimientos: [],
  loading: true,
  jwt: "",
});

// getters
const getters = {
  estData: (state) =>
    objectToBase64({
      corId: state.businessUnit,
      empId: state.company,
      estId: state.establishment,
      estIdCentral: state.estIdCentral,
    }),
};

// actions
const actions = {
  changeMenu({ commit }, toggleMini) {
    commit("setActiveMenu", toggleMini);
  },
  login({ commit }, jwt) {
    localStorage.setItem("name", jwt.token.payload.name);
    commit("clear");
    commit("setName", jwt.token.payload.name);
    commit("setUser", jwt.token.payload["custom:usu_id"]);
    commit(
      "alerts/setMessage",
      { message: "Bienvenido!", type: "success" },
      { root: true }
    );
    commit("setUserJwt", jwt.token.jwtToken);
  },
  setUser({ commit }, user) {
    commit("setUser", user);
  },
  setUserJwt({ commit }, { jwt, info }) {
    commit("setLoading", true);
    commit("setUserJwt", jwt);
    commit("setName", info.name);
    commit("setUser", info["custom:usu_id"]);
    commit("setLoading", false);
  },
  setEst({ commit }, data) {
    localStorage.setItem("establishment", data.estId);
    localStorage.setItem("establishmentName", data.estSiglas);
    localStorage.setItem("company", data.empId);
    localStorage.setItem("businessUnit", data.corId);
    localStorage.setItem("name", data.usuNombre);
    localStorage.setItem("estIdCentral", data.sadm_id_central);
    commit("setEst", data);
  },
};

// mutations
const mutations = {
  setActiveMenu(state, toggleMini) {
    state.toggleMini = toggleMini;
  },
  setBusinessUnit(state, index) {
    if (index === null) {
      state.businessUnit = index;
      state.empresas = [];
    } else {
      state.businessUnit = state.accesos[index].corId;
      state.empresas = state.accesos[index].empresas;
    }
  },
  setCompany(state, index) {
    if (index === null) {
      state.company = index;
      state.establecimientos = [];
    } else {
      state.company = state.empresas[index].empId;
      state.establecimientos = state.empresas[index].establecimientos;
    }
  },
  setEstablishment(state, index) {
    if (index === null) {
      state.establishment = index;
    } else {
      state.establishment = state.establecimientos[index].estId;
      state.establishmentName = state.establecimientos[index].siglas;
    }
  },
  setName(state, name) {
    state.name = name;
  },
  clear(state) {
    state.businessUnit = undefined;
    state.company = undefined;
    state.establishment = undefined;
    state.accesos = [];
    state.empresas = [];
    state.establecimientos = [];
    state.establishmentName = "";
    state.user = "";
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserJwt(state, jwt) {
    state.jwt = jwt;
  },
  setEst(state, est) {
    state.businessUnit = est.corId + "";
    state.company = est.empId + "";
    state.establishment = est.estId + "";
    state.name = est.usuNombre;
    state.user = est.usuId;
    state.establishmentName = est.estSiglas;
    state.estIdCentral = est.sadm_id_central;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
