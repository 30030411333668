<template>
  <v-container>
    <ImpresionFacturas />
  </v-container>
</template>
<script>
import ImpresionFacturas from "../components/ImpresionFactura";
export default {
  components: { ImpresionFacturas },
};
</script>
