<template>
  <v-container fluid>
    <v-row align-content="center">
      <!-- parte izquierda, catalogo de productos  -->
      <v-col cols="12" sm="12" md="7" class="justify-center px-md-3">
        <v-data-iterator
          :items="filteredProducts"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          :search="search"
          hide-default-footer
          :loading="loadingProductos"
        >
          <template v-slot:header>
            <v-container fluid class="block_search py-0" id="lista_productos">
              <v-card flat class="mb-5">
                <v-row class="px-lg-3" no-gutters>
                  <v-col cols="12" sm="12" class="pa-0">
                    <v-expansion-panels
                      flat
                      class="mt-0"
                      v-model="panelLineaProducto"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <template v-slot:default="{ open }">
                            <v-row>
                              <v-col
                                cols="12"
                                md="6"
                                class="pt-2 pb-3 pb-md-0 pt-lg-0 mt-lg-2"
                              >
                                <v-text-field
                                  @click.native="preventOpen($event)"
                                  dense
                                  outlined
                                  hide-details
                                  single-line
                                  clearable
                                  v-model="search"
                                  prepend-inner-icon="mdi-magnify"
                                  label="Buscar por nombre"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                                class="text--secondary"
                                align="center"
                              >
                                <v-row>
                                  <v-fade-transition group leave-absolute>
                                    <v-col cols="12" v-if="open" key="0">
                                      <span>
                                        toca para selecciona la categoria
                                      </span>
                                    </v-col>
                                    <v-col cols="12" v-else key="1">
                                      <span
                                        class="pr-3 pr-md-0 font-weight-bold"
                                        >Categoria seleccionada:
                                      </span>
                                      <div
                                        class="py-5 py-sm-0 pt-md-1 d-sm-inline-flex"
                                      >
                                        <v-chip
                                          color="my_yellow lighten-3"
                                          class="font-weight-medium"
                                          label
                                        >
                                          {{ nameCategorySelected }}
                                        </v-chip>
                                      </div>
                                    </v-col>
                                  </v-fade-transition>
                                </v-row>
                              </v-col>
                            </v-row>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!--lineas de producto-->
                          <v-row
                            class="pt-5 pa-sm-5 overflow-y-auto overflow-x-hidden linea-producto_container"
                          >
                            <v-col
                              cols="6"
                              md="4"
                              v-for="lineaProducto in lineasProducto"
                              :key="lineaProducto.sinv_lpr_id"
                            >
                              <v-card
                                class="py-4"
                                :min-height="$vuetify.breakpoint.xs ? 100 : 50"
                                :color="
                                  lineaProductoActiva ===
                                  lineaProducto.sinv_lpr_id
                                    ? 'my_yellow lighten-3'
                                    : 'primary'
                                "
                                :dark="
                                  lineaProductoActiva !==
                                  lineaProducto.sinv_lpr_id
                                "
                                :class="
                                  lineaProductoActiva ===
                                  lineaProducto.sinv_lpr_id
                                    ? 'font-weight-bold'
                                    : ''
                                "
                                @click="
                                  handleLineaProducto(lineaProducto.sinv_lpr_id)
                                "
                              >
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="pa-5 pa-sm-2 px-md-5 text-center"
                                  >
                                    {{
                                      lineaProducto.sinv_lpr_descripcion.toUpperCase()
                                    }}
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                          <!--categorias-->
                          <v-row v-if="showCategorias">
                            <v-col cols="12" class="text-center">
                              <h4 class="primary white--text py-3">
                                Sub categorias
                              </h4>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="showCategorias"
                            class="pt-5 pa-sm-5 overflow-y-auto overflow-x-hidden categories_container"
                          >
                            <v-col
                              cols="6"
                              md="4"
                              v-for="categoria in categoriasLineasProducto"
                              :key="categoria.sinv_cat_id"
                            >
                              <v-card
                                class="py-4"
                                :min-height="$vuetify.breakpoint.xs ? 100 : 50"
                                :color="
                                  categoriaActiva === categoria.sinv_cat_id
                                    ? 'my_yellow lighten-3'
                                    : 'primary'
                                "
                                :dark="
                                  categoriaActiva !== categoria.sinv_cat_id
                                "
                                :class="
                                  categoriaActiva === categoria.sinv_cat_id
                                    ? 'font-weight-bold'
                                    : ''
                                "
                                @click="handleCategoria(categoria.sinv_cat_id)"
                              >
                                <v-row>
                                  <v-col
                                    cols="12"
                                    class="pa-5 pa-sm-2 px-md-5 text-center"
                                  >
                                    {{
                                      categoria.sinv_cat_descripcion.toUpperCase()
                                    }}
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </template>
          <template v-slot:default="props">
            <div class="overflow-y-auto overflow-x-hidden py-0 div-sales">
              <v-row class="pt-3 px-md-5">
                <v-col
                  cols="6"
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="item in props.items"
                  :key="item.sinv_pro_codigo"
                >
                  <v-card
                    class="mx-auto"
                    :class="existInDetail(item) ? 'primary elevation-2  ' : ''"
                    max-width="250"
                    min-height="225"
                    flat
                    @click="handleAddItem(item)"
                  >
                    <v-img
                      min-height="186"
                      :src="
                        item.sinv_pro_pos_imagen
                          ? item.sinv_pro_pos_imagen
                          : defaultImageUrl
                      "
                      class="align-end"
                    >
                    </v-img>
                    <v-chip
                      class="chip_price font-weight-bold my_yellow lighten-3"
                      label
                      small
                    >
                      {{ formatCurrencyGT(item.sinv_pro_precio) }}
                    </v-chip>
                    <v-card-subtitle class="py-1">
                      <div
                        class="name_item text-justify font-weight-medium"
                        :class="existInDetail(item) ? 'white--text' : ''"
                      >
                        <small>
                          {{
                            item.sinv_pro_descripcion_pos ||
                            item.sinv_pro_descripcion
                          }}
                        </small>
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:footer>
            <v-card flat>
              <v-row
                class="mt-2 mx-md-5"
                align="center"
                justify="center"
                no-gutters
              >
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  align="center"
                  class="justify-center my-2"
                >
                  <span class="grey--text">productos por página</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        text
                        color="primary"
                        elevation="0"
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(number, index) in itemsPerPageArray"
                        :key="index"
                        @click="updateItemsPerPage(number)"
                      >
                        <v-list-item-title>{{ number }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  :align="$vuetify.breakpoint.xs ? 'center' : 'right'"
                  class="justify-center pb-5 pb-sm-1"
                >
                  <v-spacer></v-spacer>
                  <span class="mr-4 grey--text">
                    Página {{ page }} de {{ numberOfPages }}
                  </span>
                  <v-btn
                    small
                    dark
                    color="secondary"
                    elevation="0"
                    class="mr-1"
                    @click="formerPage"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    dark
                    color="secondary"
                    class="ml-1"
                    @click="nextPage"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <template v-slot:loading>
            <div class="p-md-6 div-sales">
              <v-card flat class="div-sales pt-15">
                <v-row class="pt-3 pt-md-15 px-md-6" no-gutters align="center">
                  <v-col
                    cols="12"
                    class="p-0 m-0 pt-15"
                    align="center"
                    justify="center"
                  >
                    <loader />
                    <!--                    <v-icon style="font-size: 3em">
                        mdi-store-clock-outline
                      </v-icon>-->
                  </v-col>
                  <v-col
                    cols="12"
                    class="p-0 grey--text text--darken-3"
                    align="center"
                    justify="center"
                  >
                    <h2 class="primary--text">¡Un momento por favor!</h2>
                    <small class="font-weight-medium">
                      Estamos obteniendo tus productos.
                    </small>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </template>
          <template v-slot:no-results>
            <div class="p-md-6 div-sales">
              <v-card flat class="div-sales pt-15">
                <v-row class="pt-3 pt-md-15 px-md-6" no-gutters align="center">
                  <v-col
                    cols="12"
                    class="p-0 m-0 pt-15"
                    align="center"
                    justify="center"
                  >
                    <v-icon style="font-size: 3em">
                      mdi-package-variant-remove
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    class="p-0 grey--text text--darken-3"
                    align="center"
                    justify="center"
                  >
                    <h2 class="primary--text">¡Lo sentimos!</h2>
                    <small class="font-weight-medium">
                      No encontramos algún producto con esos datos de busqueda.
                    </small>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </template>
          <template v-slot:no-data>
            <div
              v-show="
                filteredProducts.length <= 0 && loadingProductos === false
              "
              class="p-md-6 div-sales"
            >
              <v-card flat class="div-sales pt-15">
                <v-row class="pt-3 pt-md-15 px-md-6" no-gutters align="center">
                  <v-col
                    cols="12"
                    class="p-0 m-0 pt-15"
                    align="center"
                    justify="center"
                  >
                    <v-icon style="font-size: 3em">
                      mdi-folder-information-outline
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    class="p-0 grey--text text--darken-3"
                    align="center"
                    justify="center"
                  >
                    <h2 class="primary--text">¡Sin productos!</h2>
                    <small class="font-weight-medium"
                      >Lo sentimos, no hay productos configurados para tu
                      establecimiento.</small
                    >
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </template>
        </v-data-iterator>
      </v-col>
      <!-- parte derecha carrito de compras -->
      <v-col
        v-show="$vuetify.breakpoint.smAndUp"
        cols="12"
        sm="12"
        md="5"
        class="justify-center px-md-3"
        id="detalle_compra"
      >
        <v-card flat>
          <v-container>
            <v-row class="justify-center">
              <!-- totales -->
              <v-list-item>
                <v-list-item-content>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="primary"
                          @click="cobrar"
                          elevation="0"
                        >
                          Cobrar {{ formatCurrencyGT(total) }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-row class="justify-center">
              <!-- listado de productos -->
              <v-list-item v-if="detalle.length < 1">
                <v-list-item-content>
                  <v-row align="center" style="min-height: 562px">
                    <v-col col="12" align="center">
                      <div class="p-md-6 py-14">
                        <v-card flat class="py-14">
                          <v-row
                            class="pt-3 py-md-14 px-md-6"
                            no-gutters
                            align="center"
                          >
                            <v-col
                              cols="12"
                              class="p-0 m-0 pt-15"
                              align="center"
                              justify="center"
                            >
                              <v-icon style="font-size: 3em">
                                mdi-cart-outline
                              </v-icon>
                            </v-col>
                            <v-col
                              cols="12"
                              class="p-0 grey--text text--darken-3"
                              align="center"
                              justify="center"
                            >
                              <h2 class="primary--text">¡Sin productos!</h2>
                              <small class="font-weight-medium">
                                Aún no has agregado productos a la factura.
                              </small>
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-content>
                  <v-virtual-scroll
                    class="overflow-x-hidden"
                    :items="detalle"
                    min-height="542"
                    max-height="542"
                    :item-height="$vuetify.breakpoint.mdAndDown ? 175 : 76"
                  >
                    <template v-slot:default="{ item }">
                      <v-fade-transition leave-absolute>
                        <v-card outlined min-height="70">
                          <v-row align="center">
                            <v-col
                              cols="6"
                              lg="1"
                              class="fill-height"
                              order="2"
                              order-lg="1"
                            >
                              <div
                                class="d-flex justify-center"
                                :class="
                                  $vuetify.breakpoint.mdAndDown
                                    ? ''
                                    : 'float-left pl-1'
                                "
                              >
                                <v-btn
                                  @click="editProductQuantity(item)"
                                  :icon="!$vuetify.breakpoint.mdAndDown"
                                  :block="$vuetify.breakpoint.mdAndDown"
                                  elevation="0"
                                  class="align-center"
                                >
                                  <v-icon>mdi-pen</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="10" order="1" order-lg="2">
                              <v-row class="px-3 py-2" align="center">
                                <v-col
                                  cols="2"
                                  lg="2"
                                  order="1"
                                  order-md="1"
                                  order-lg=""
                                  align="center"
                                >
                                  <h3>{{ item.cantidad }}</h3>
                                  <p class="support--text lighten-3">
                                    {{
                                      item.sinv_ume_siglas.toUpperCase() || ""
                                    }}
                                  </p>
                                </v-col>
                                <v-col
                                  cols="10"
                                  lg="8"
                                  order="2"
                                  order-sm="2"
                                  order-md="2"
                                  order-lg=""
                                >
                                  <small class="support--text lighten-3">
                                    {{ item.sinv_pro_codigo }}
                                  </small>
                                  <p class="font-weight-medium text-lg-body-2">
                                    {{
                                      displayDetailName(
                                        item.sinv_pro_descripcion
                                      )
                                    }}
                                  </p>
                                  <small class="support--text lighten-3">
                                    {{ formatCurrencyGT(item.sinv_pro_precio) }}
                                  </small>
                                </v-col>
                                <v-col
                                  cols="12"
                                  lg="2"
                                  order="3"
                                  :align="
                                    $vuetify.breakpoint.mdAndDown
                                      ? 'right'
                                      : 'center'
                                  "
                                  :class="
                                    $vuetify.breakpoint.mdAndDown ? 'py-0' : ''
                                  "
                                >
                                  <small class="support--text lighten-3"
                                    >subtotal</small
                                  >
                                  <small class="pt-1 font-weight-medium">
                                    {{ formatCurrencyGT(item.subtotal) }}
                                  </small>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              lg="1"
                              class="fill-height"
                              order="3"
                              order-sm=""
                              order-md="3"
                              order-lg="3"
                            >
                              <div
                                class="d-flex justify-center"
                                :class="
                                  $vuetify.breakpoint.mdAndDown
                                    ? ''
                                    : 'float-right  pr-1'
                                "
                              >
                                <v-btn
                                  @click="removeProduct(item)"
                                  :icon="!$vuetify.breakpoint.mdAndDown"
                                  :block="$vuetify.breakpoint.mdAndDown"
                                  :dark="$vuetify.breakpoint.mdAndDown"
                                  elevation="0"
                                  color=" red lighten-1"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-fade-transition>
                    </template>
                  </v-virtual-scroll>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogCobro"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Facturación</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-row v-if="$vuetify.breakpoint.smAndUp && !showPrint">
            <v-col cols="12" order="2" order-sm="1">
              <v-row class="pb-2">
                <v-col cols="6" md="6" class="px-md-16 px-10">
                  <v-btn
                    color="white"
                    :disabled="loadingFacturar"
                    outlined
                    large
                    block
                    @click="goBack"
                  >
                    <v-icon left> mdi-arrow-left </v-icon>
                    VOLVER
                  </v-btn>
                </v-col>
                <v-col cols="6" md="6" class="px-md-16 px-10">
                  <v-btn
                    color="white"
                    outlined
                    :loading="loadingFacturar"
                    large
                    block
                    @click="facturar"
                    elevation="0"
                  >
                    <v-icon left> mdi-cash-check </v-icon>
                    Facturar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="goBack"
              :disabled="loadingFacturar || loadingPrint"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <v-col cols="12" class="px-0">
            <v-card flat class="px-0" v-if="!showPrint">
              <v-card-text class="">
                <v-row>
                  <v-col cols="12" sm="6" class="blue-grey lighten-5">
                    <v-card flat class="mt-5 mb-9">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <template v-slot:default="{ open }">
                              <v-row>
                                <v-col
                                  cols="12"
                                  md="6"
                                  class="text--secondary"
                                  align="center"
                                >
                                  <v-row>
                                    <v-fade-transition group leave-absolute>
                                      <v-col cols="12" v-if="open" key="0">
                                        <span class="pl-3">
                                          Toca para ocultar
                                        </span>
                                      </v-col>
                                      <v-col cols="12" v-else key="1">
                                        <span
                                          class="pr-3 pr-md-0 font-weight-bold"
                                          >Datos del Cliente :
                                        </span>
                                      </v-col>
                                    </v-fade-transition>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-form ref="formClient">
                              <v-row class="px-3 pt-5">
                                <v-col cols="12" md="6" class="pl-5">
                                  <h3 class="text--secondary">
                                    Datos del cliente
                                  </h3>
                                </v-col>
                                <v-col cols="12" md="6" sm="6" class="px-5">
                                  <v-text-field
                                    dense
                                    autofocus
                                    label="NIT"
                                    hide-details="auto"
                                    v-model="client.nit"
                                    placeholder="Ingresa NIT o CF"
                                    hint="Se buscará automaticamente los datos del cliente. (sin espacios ni guiones)"
                                    prepend-icon="mdi-magnify"
                                    @change="find = false"
                                    :loading="loadingClient"
                                    clearable
                                    @blur="
                                      () => {
                                        !find && searchClient();
                                      }
                                    "
                                    @click="
                                      client.nit =
                                        client.nit === 'CF' ? '' : client.nit
                                    "
                                    @click:append="
                                      () => {
                                        searchClient();
                                      }
                                    "
                                    v-on:keyup.enter="
                                      () => {
                                        searchClient();
                                      }
                                    "
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row
                                v-if="loadingClient"
                                class="px-3 pb-4"
                                align="center"
                              >
                                <v-col
                                  cols="12"
                                  align="center"
                                  justify="center"
                                  class="pb-0 mb-0"
                                >
                                  <!--                            <v-progress-circular
                                size="20"
                                indeterminate
                                color="primary"
                              ></v-progress-circular>-->
                                  <loader mini />
                                </v-col>
                                <v-col cols="12" align="center">
                                  <h2 class="primary--text">
                                    ¡Un momento por favor!
                                  </h2>
                                  <small class="font-weight-medium">
                                    Estamos buscando el cliente.
                                  </small>
                                </v-col>
                              </v-row>
                              <v-row v-else class="px-3 pb-4 pt-0">
                                <v-col cols="12" md="6" sm="6" class="px-5">
                                  <v-text-field
                                    dense
                                    label="Nombre"
                                    hide-details="auto"
                                    v-model="client.nombre"
                                    :rules="nameRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" sm="6" class="px-5">
                                  <v-text-field
                                    dense
                                    label="Correo"
                                    hide-details="auto"
                                    v-model="client.correo"
                                    :rules="emailRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" sm="6" class="px-5">
                                  <v-text-field
                                    dense
                                    label="Dirección"
                                    hide-details="auto"
                                    v-model="client.direccion"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                    <v-card
                      flat
                      class="my-5 py-5"
                      min-height="265"
                      v-show="$vuetify.breakpoint.smAndUp"
                    >
                      <v-simple-table fixed-header height="265px" class="mx-4">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">cantidad</th>
                              <th class="text-left">Producto</th>
                              <th class="text-left">subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in detalle" :key="item.id">
                              <td>
                                {{ item.cantidad }}
                              </td>
                              <td>
                                {{ item.sinv_pro_descripcion }}
                              </td>
                              <td>
                                {{ formatCurrencyGT(item.subtotal) }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                    <v-card flat class="primary--text my-3 py-5 text-center">
                      <h1>
                        TOTAL:
                        <span class="support--text">{{
                          formatCurrencyGT(total)
                        }}</span>
                      </h1>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <pago
                      @setPricePago="setPricePago"
                      @setCambio="setCambio"
                      @handleTipoPago="setTipoPago"
                      v-if="dialogCobro"
                      :totalPago="total"
                      :detalle="detalle"
                    />
                  </v-col>
                  <v-col cols="12" v-if="$vuetify.breakpoint.xs">
                    <v-row class="pb-2">
                      <v-col cols="6" md="6" class="px-md-16 px-10">
                        <v-btn
                          color="primary"
                          :disabled="loadingFacturar"
                          outlined
                          large
                          block
                          @click="goBack"
                        >
                          <v-icon left> mdi-arrow-left </v-icon>
                          VOLVER
                        </v-btn>
                      </v-col>
                      <v-col cols="6" md="6" class="px-md-16 px-10">
                        <v-btn
                          color="primary"
                          :loading="loadingFacturar"
                          large
                          block
                          @click="facturar"
                          elevation="0"
                        >
                          <v-icon left> mdi-cash-check </v-icon>
                          Facturar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card flat v-else class="pt-8">
              <v-card-text>
                <v-row class="" no-gutters>
                  <!--                  <v-col cols="12" md="6">
                      <v-row>
                        <v-col
                          cols="12"
                          v-if="url !== ''"
                          class="mt-10 d-flex justify-center flex-row"
                          align="center"
                        >
                          <div class="fill-height mr-10">
                            <v-row
                              align="center"
                              justify="center"
                              class="fill-height"
                            >
                              <v-col cols="12" align-self="center">
                                <v-btn
                                  :disabled="pagePdf === 1"
                                  @click="pagePdf > 1 ? pagePdf&#45;&#45; : 1"
                                  color="primary"
                                  icon
                                  outlined
                                >
                                  <v-icon> mdi-chevron-left </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>

                          <div class="pb-4 px-3 colBorder">
                            <pdf :src="url" :page="pagePdf" class=""> </pdf>
                          </div>

                          <div class="fill-height ml-10">
                            <v-row
                              align="center"
                              justify="center"
                              class="fill-height"
                            >
                              <v-col cols="12" align-self="center">
                                <v-btn
                                  icon
                                  outlined
                                  color="primary"
                                  :disabled="pagePdf === numPages"
                                  @click="pagePdf < numPages ? pagePdf++ : 1"
                                >
                                  <v-icon> mdi-chevron-right </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>-->
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col
                        cols="12"
                        class="p-0 m-0 pt-15"
                        align="center"
                        justify="center"
                      >
                        <img src="img/factura.gif" alt="factura" width="150" />
                      </v-col>
                      <v-col
                        cols="12"
                        class="p-0 grey--text text--darken-3"
                        align="center"
                        justify="center"
                      >
                        <h2 class="primary--text">¡Factura generada!</h2>
                        <small class="font-weight-medium"
                          >Recuerda que puedes consultarla en tu lista de
                          facturas.</small
                        >
                      </v-col>
                      <v-col
                        cols="10"
                        sm="6"
                        align="center"
                        class="px-10 py-3 offset-sm-3 offset-1"
                      >
                        <!--                        <v-btn
                            block
                            outlined
                            @click="print"
                            color="primary"
                            class="py-5 my-10"
                            :loading="loadingPrint"
                          >
                            <v-icon class="pr-4"> mdi-printer-outline </v-icon>
                            imprimir
                          </v-btn>-->
                        <v-btn
                          block
                          outlined
                          @click="go_print"
                          color="primary"
                          class="py-5 my-10"
                          :loading="loadingPrint"
                        >
                          <v-icon class="pr-4"> mdi-printer-outline </v-icon>
                          imprimir
                        </v-btn>
                        <v-btn
                          block
                          outlined
                          @click="dialogSendEmail = true"
                          color="primary"
                          class="py-5 my-10"
                          :loading="loadingPrint"
                        >
                          <v-icon class="pr-4"> mdi-email-send-outline </v-icon>
                          Enviar
                        </v-btn>
                        <v-btn
                          block
                          outlined
                          color="support"
                          class="py-5"
                          elevation="0"
                          @click="closeSale"
                          :disabled="loadingPrint"
                        >
                          <v-icon class="pr-4"> mdi-close </v-icon>
                          cerrar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDetail"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Productos en la factura</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialogDetail = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <v-col cols="12" class="px-0">
            <v-card flat class="pt-1">
              <v-card-text>
                <v-row class="justify-center">
                  <v-col cols="12">
                    <!-- totales -->
                    <v-list-item>
                      <v-list-item-content>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-btn
                                block
                                color="primary"
                                @click="cobrar"
                                elevation="0"
                              >
                                Cobrar {{ formatCurrencyGT(total) }}
                              </v-btn>
                              {{ tipoPago }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-btn
                                block
                                color="primary"
                                @click="dialogDetail = false"
                                elevation="0"
                                outlined
                              >
                                <v-icon> mdi-arrow-left </v-icon>
                                Seguir comprando
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="detalle.length < 1">
                  <v-col cols="12" class="justify-center px-md-3">
                    <v-list-item>
                      <v-list-item-content>
                        <v-row align="center" style="min-height: 562px">
                          <v-col col="12" align="center">
                            <div class="p-md-6 py-14">
                              <v-card flat class="py-14">
                                <v-row
                                  class="pt-3 py-md-14 px-md-6"
                                  no-gutters
                                  align="center"
                                >
                                  <v-col
                                    cols="12"
                                    class="p-0 m-0 pt-15"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-icon style="font-size: 3em">
                                      mdi-cart-outline
                                    </v-icon>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    class="p-0 grey--text text--darken-3"
                                    align="center"
                                    justify="center"
                                  >
                                    <h2 class="primary--text">
                                      ¡Sin productos!
                                    </h2>
                                    <small class="font-weight-medium">
                                      Aún no has agregado productos a la
                                      factura.
                                    </small>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </div>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <v-card
                      class="mx-auto"
                      flat
                      max-width="600"
                      min-height="600"
                    >
                      <v-card-text class="pt-4">
                        Estos son los productos que actualmente estan en la
                        factura, puedes cambiar su cantidad o eliminarlos.
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-virtual-scroll
                        :items="detalle"
                        :item-height="170"
                        height="600"
                      >
                        <template v-slot:default="{ item }">
                          <v-list-item>
                            <v-list-item-content>
                              <v-card outlined>
                                <v-row class="pa-3" align="center">
                                  <v-col cols="12">
                                    <v-row>
                                      <v-col cols="2" align="center">
                                        <h3>{{ item.cantidad }}</h3>
                                        <p class="support--text lighten-3">
                                          {{
                                            item.sinv_ume_siglas.toUpperCase() ||
                                            ""
                                          }}
                                        </p>
                                      </v-col>
                                      <v-col cols="10">
                                        <small class="support--text lighten-3">
                                          {{ item.sinv_pro_codigo }}
                                        </small>
                                        <p
                                          class="font-weight-medium text-lg-body-2"
                                        >
                                          {{
                                            displayDetailName(
                                              item.sinv_pro_descripcion
                                            )
                                          }}
                                        </p>
                                        <small class="support--text lighten-3">
                                          {{
                                            formatCurrencyGT(
                                              item.sinv_pro_precio
                                            )
                                          }}
                                        </small>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" align="right" class="py-0">
                                    <small class="support--text lighten-3">
                                      subtotal
                                    </small>
                                    <small class="pt-1 font-weight-medium">
                                      {{ formatCurrencyGT(item.subtotal) }}
                                    </small>
                                  </v-col>
                                  <v-col
                                    cols="6"
                                    lg="1"
                                    class="fill-height"
                                    order="2"
                                    order-lg="1"
                                  >
                                    <div
                                      class="d-flex justify-center"
                                      :class="
                                        $vuetify.breakpoint.mdAndDown
                                          ? ''
                                          : 'float-left pl-1'
                                      "
                                    >
                                      <v-btn
                                        @click="editProductQuantity(item)"
                                        :icon="!$vuetify.breakpoint.mdAndDown"
                                        :block="$vuetify.breakpoint.mdAndDown"
                                        elevation="0"
                                        class="align-center"
                                      >
                                        <v-icon>mdi-pen</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                  <v-col
                                    cols="6"
                                    lg="1"
                                    class="fill-height"
                                    order="3"
                                    order-sm=""
                                    order-md="3"
                                    order-lg="3"
                                  >
                                    <div
                                      class="d-flex justify-center"
                                      :class="
                                        $vuetify.breakpoint.mdAndDown
                                          ? ''
                                          : 'float-right  pr-1'
                                      "
                                    >
                                      <v-btn
                                        @click="removeProduct(item)"
                                        :icon="!$vuetify.breakpoint.mdAndDown"
                                        :block="$vuetify.breakpoint.mdAndDown"
                                        :dark="$vuetify.breakpoint.mdAndDown"
                                        elevation="0"
                                        color=" red lighten-1"
                                      >
                                        <v-icon>mdi-close</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-virtual-scroll>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditQuantity" width="430" persistent>
      <v-card class="pa-2">
        <v-card-title class="text-h6 primary--text">
          <v-icon large color="primary" class="pr-2"> mdi-swap-vertical</v-icon>
          Cambiar cantidad
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row class="pt-5">
                <v-col cols="4" md="4">
                  <v-btn
                    class="py-7"
                    color="primary"
                    elevation="0"
                    block
                    @click="handleProductQuantity(producto.cantidad - 1)"
                  >
                    <v-icon> mdi-minus </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field readonly v-model="producto.cantidad" outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-btn
                    class="py-7"
                    color="primary"
                    elevation="0"
                    block
                    @click="handleProductQuantity(producto.cantidad + 1)"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="support" text @click="closeDialogProductQuantity">
            <v-icon> mdi-arrow-left </v-icon>
            volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="confirmDialogProductQuantity">
            aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--boton lista-->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="$vuetify.breakpoint.smAndDown"
          @click.stop="dialogDetail = !dialogDetail"
          fab
          fixed
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          style="bottom: 70px"
        >
          <v-icon> mdi-cart-outline </v-icon>
          <v-chip x-small color="secondary" class="chip_cart pa-1">
            {{ totalItems }}
          </v-chip>
        </v-btn>
      </template>
      <span>Detalle</span>
    </v-tooltip>
    <send-email
      v-if="dialogSendEmail"
      @close="dialogSendEmail = false"
      :email-client="client.correo"
    >
    </send-email>
  </v-container>
</template>
<script>
import Pago from "../components/Pago.vue";
import { formatCurrencyGT } from "@/core/utils";
import { getClient } from "@/api/client";
import { mapState, mapActions } from "vuex";
import SendEmail from "../components/SendEmail";
import {
  chapincito64,
  convertIntObj,
  defaultLogo64,
  generateBodyFelPDF,
  generateParamsCertificarPDF,
  getTipoDocumentoName,
  logoCafeQuetzalBistro,
  logoPollo64,
} from "../utils";
import Loader from "../components/loader";
export default {
  name: "NewInvoice",
  components: { Loader, Pago, SendEmail },
  data() {
    return {
      flagCF: true,
      dialogSendEmail: false,
      dialogDetail: false,
      dialogEditQuantity: false,
      panelLineaProducto: false,
      page: 1,
      itemsPerPageArray: [4, 8, 12],
      itemsPerPage: 8,
      search: "",
      benched: 0,
      detalle: [],
      producto: {},
      dialog: false,
      dialogCobro: false,
      subtotal: 0,
      cantidad: 0,
      pricePago: 0,
      tipoPago: "",
      dialogFactura: false,
      showPrint: false,
      defaultImageUrl: "/img/default-product.png",
      lineaProductoActiva: "No existen categorias",
      loadingFacturar: false,
      loadingPrint: false,
      client: {
        nit: "CF",
        nombre: "Consumidor Final",
        direccion: "Ciudad",
        correo: "correo@correo.com",
        vendedor: "Directos",
        codigo: "",
      },
      defaultClient: {
        nit: "CF",
        nombre: "Consumidor Final",
        direccion: "Ciudad",
        correo: "",
        vendedor: "Directos",
        codigo: "",
      },
      find: false,
      emailRules: [
        (value) =>
          !value ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "Ingresa un correo válido",
      ],
      nameRules: [(value) => !!value || "Ingresa un nombre o Consumidor Final"],
      loadingClient: false,
      url: "",
      pagePdf: 1,
      numPages: 0,
      color: "#000000",
      logo: {
        logoX: 23,
        logoY: 5,
        logoAncho: 10,
        logoAlto: 10,
      },
      font: {
        fontNombreComercial: 7,
        fontRazonSocial: 7,
        fontDatosEmpresa: 7,
        fontDatosFactura: 7,
        fontDatosCliente: 7,
        fontDetalle: 7,
      },
      textos: [
        {
          textoFactura: "Gracias por su compra , vuelva pronto.",
          textoX: 1,
          textoY: 0,
          textoAncho: 0,
          textoAlto: 0,
          textoFont: 7,
          textoAlineacion: "Justificar",
          marco: false,
        },
      ],
      dataToPrint: [],
      chapincitoIds: ["1298", "1856"],
      pollolenaIds: [
        "898",
        "901",
        "902",
        "903",
        "904",
        "905",
        "906",
        "907",
        "908",
        "909",
        "910",
        "911",
        "912",
        "913",
        "914",
        "915",
        "916",
        "917",
        "918",
        "919",
        "920",
        "921",
        "922",
        "923",
        "924",
        "925",
        "1099",
        "1100",
        "2227",
      ],
      showCategorias: false,
      categoriasLineasProducto: [],
      categoriaActiva: "",
    };
  },
  computed: {
    ...mapState({
      productos: (state) => state.products.products,
      lineasProducto: (state) => state.categories.lineasProducto,
      categorias: (state) => state.categories.categorias,
      loadingProductos: (state) => state.products.loading,
      establishmentParams: (state) => state.establishment.establishmentParams,
      userLoginName: (state) => state.config.name,
      establishment: (state) => state.config.establishment,
    }),
    nameCategorySelected() {
      const categoria = this.lineasProducto.find(
        (categoria) => categoria.sinv_lpr_id == this.lineaProductoActiva
      );
      return (
        categoria?.sinv_lpr_descripcion.toUpperCase() || "No existen categorias"
      );
    },
    numberOfPages() {
      return Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    },
    filteredProducts() {
      if (this.lineaProductoActiva === "No existen categorias") {
        return this.productos.slice();
      }

      return this.productos.slice().filter((producto) => {
        if (producto.sinv_lpr_id === this.lineaProductoActiva) {
          if (
            this.categoriaActiva !== "" &&
            producto.sinv_cat_id !== this.categoriaActiva
          ) {
            return false; // Si la categoría activa no coincide, se excluye el producto
          }
          return true; // Si la categoría activa coincide o no se proporciona una categoría activa, se incluye el producto
        }
        return false; // Si la línea de producto no coincide, se excluye el producto
      });
    },
    total() {
      return this.detalle.reduce(
        (partialSum, { subtotal }) =>
          parseFloat(partialSum) + parseFloat(subtotal),
        0
      );
    },
    totalItems() {
      return this.detalle.reduce(
        (partialSum, { cantidad }) => parseInt(partialSum) + parseInt(cantidad),
        0
      );
    },
    nitCliente() {
      return this.client.nit;
    },
  },
  watch: {
    nitCliente() {
      if (!this.nitCliente) {
        this.client.nombre = "";
        this.client.correo = "";
        this.client.direccion = "";
        this.client.vendedor = "";
        this.$refs.formClient.resetValidation();
        this.find = false;
      }
    },
    categorias() {
      this.selectCategoria();
    },
  },
  methods: {
    selectCategoria() {
      if (this.lineasProducto.length > 0) {
        this.lineaProductoActiva = this.lineasProducto[0].sinv_lpr_id;
      }
    },
    ...mapActions({
      certificarDocumento: "invoices/certificarDocumento",
      getProductos: "products/getProductos",
    }),
    editProductQuantity(producto) {
      this.producto = { ...producto };
      this.dialogEditQuantity = true;
    },
    handleProductQuantity(newQuantity) {
      if (newQuantity < 1) {
        this.producto.cantidad = 1;
        return;
      }
      this.producto.cantidad = newQuantity;
    },
    confirmDialogProductQuantity() {
      let index = this.detalle.findIndex(
        (item) => item.sinv_pro_codigo === this.producto.sinv_pro_codigo
      );
      if (index !== -1) {
        this.detalle[index].cantidad = this.producto.cantidad;
        this.detalle[index].subtotal = this.subtotalForProduct(
          this.producto.cantidad,
          this.detalle[index].sinv_pro_precio
        );
      }
      this.closeDialogProductQuantity();
    },
    closeDialogProductQuantity() {
      this.dialogEditQuantity = false;
      this.producto = {};
    },
    closeSale() {
      this.dialogCobro = false;
      this.showPrint = false;
      this.clean();
    },
    goBack() {
      this.dialogCobro = false;
      this.setPricePago(0);
      this.setCambio(0);
      this.$refs.formClient.resetValidation();
    },
    clean() {
      this.detalle = [];
      this.client = { ...this.defaultClient };
      this.lineaProductoActiva = "No existen categorias";
      this.categoriaActiva = "";
      this.setPricePago(0);
      this.setCambio(0);
    },
    displayDetailName(name) {
      if (name.length > 35) {
        return name.substring(0, 35) + "...";
      }
      return name;
    },
    preventOpen: function (e) {
      e.cancelBubble = true;
    },
    handleLineaProducto(lineaProductoSelected) {
      this.showCategorias = false;
      this.categoriaActiva = "";
      this.lineaProductoActiva = lineaProductoSelected;
      let categorias = this.getCategoriasLineaProducto();
      if (categorias.length > 0) {
        this.categoriasLineasProducto = categorias;
        this.showCategorias = true;
        return;
      }
      this.panelLineaProducto = false;
      this.page = 1;
    },
    handleCategoria(categoriaSelected) {
      this.categoriaActiva = categoriaSelected;
      this.panelLineaProducto = false;
      this.page = 1;
    },
    getCategoriasLineaProducto() {
      return this.categorias.slice().reduce((nuevoArray, item) => {
        if (item.sinv_lpr_id === this.lineaProductoActiva) {
          const categoriasFiltradas = item.categorias
            .slice()
            .filter(
              (categoria) =>
                !categoria.sinv_cat_descripcion.toLowerCase().includes("ningun")
            );

          if (categoriasFiltradas.length > 0) {
            nuevoArray.push(...categoriasFiltradas);
            nuevoArray.unshift({
              sinv_cat_id: "",
              sinv_cat_descripcion: "TODOS",
            });
          }
        }
        return nuevoArray.sort();
      }, []);
    },
    formatCurrencyGT(value) {
      return formatCurrencyGT(value);
    },
    searchClient() {
      this.flagCF = false;
      this.find = true;
      if (this.client.nit.toUpperCase() === "CF") {
        this.client.nit = "CF";
        this.client.nombre = "Consumidor Final";
        this.client.direccion = "Ciudad";
        this.client.vendedor = "Directos";
        this.client.codigo = "";
        return;
      }

      if (this.client.nit) {
        const pattern = /^[0-9kK]+$/;
        if (!pattern.test(this.client.nit)) {
          this.client.nit = this.client.nit.replace(/[^0-9kK]+/g, "");
          this.$store.dispatch("alerts/setMessage", {
            message: "Nit no valido",
            type: "warning",
          });
          return;
        }

        this.loadingClient = true;
        this.$apollo
          .query({
            query: getClient,
            variables: { nit: this.client.nit },
          })
          .then(({ data }) => {
            if (data.getCliente.nombre === "Consumidor Final") {
              this.client = { ...this.defaultClient };
              this.$store.dispatch("alerts/setMessage", {
                message: "Nit no encontrado",
                type: "warning",
              });
            } else {
              this.client.nit = data.getCliente.nit
                ? data.getCliente.nit
                : "CF";
              this.client.nombre = data.getCliente.nombre
                ? data.getCliente.nombre
                : "";
              this.client.correo = data.getCliente.correo
                ? data.getCliente.correo
                : "";
              this.client.direccion = data.getCliente.direccion
                ? data.getCliente.direccion
                : "";
              this.client.vendedor = data.getCliente.vendedor
                ? data.getCliente.vendedor
                : "";
              this.client.codigo = data.getCliente.codigo
                ? data.getCliente.codigo
                : "";
            }
            this.loadingClient = false;
          })
          .catch(() => {
            this.loadingClient = false;
          });
      }
    },
    openModal(producto) {
      this.producto = producto;
      this.dialog = true;
    },
    setPrice(price) {
      this.subtotal = price * this.producto.precio;
      this.cantidad = price;
    },
    setPricePago(price) {
      this.pricePago = price;
    },
    setCambio(cambio) {
      this.cambio = cambio;
    },
    setTipoPago(tipoPago) {
      this.tipoPago = tipoPago;
    },
    existInDetail(producto) {
      let index = this.detalle.findIndex(
        (item) => item.sinv_pro_codigo === producto.sinv_pro_codigo
      );
      return index !== -1;
    },
    handleAddItem(producto) {
      let index = this.detalle.findIndex(
        (item) => item.sinv_pro_codigo === producto.sinv_pro_codigo
      );
      index === -1 ? this.addProduct(producto) : this.updateProduct(index);
    },
    updateProduct(index) {
      this.detalle[index].cantidad += 1;
      this.detalle[index].subtotal = this.subtotalForProduct(
        this.detalle[index].cantidad,
        this.detalle[index].sinv_pro_precio
      );
    },
    addProduct(producto) {
      this.detalle.push({
        ...producto,
        cantidad: 1,
        subtotal: this.subtotalForProduct(1, producto.sinv_pro_precio),
      });
    },
    removeProduct(producto) {
      let index = this.detalle.findIndex(
        (item) => item.sinv_pro_codigo === producto.sinv_pro_codigo
      );
      this.detalle.splice(index, 1);
    },
    subtotalForProduct(quantity, price) {
      return parseFloat(quantity * price).toFixed(2);
    },
    cobrar() {
      this.searchClient();
      this.flagCF = true;
      if (this.detalle.length == 0) {
        this.$store.dispatch("alerts/setMessage", {
          message: "No hay productos en la compra.",
          type: "warning",
        });
        return;
      }
      if (this.$vuetify.breakpoint.xs) {
        this.dialogDetail = false;
      }
      this.dialogCobro = true;
    },
    async facturar() {
      if (!this.flagCF) {
        if (!this.$refs.formClient.validate()) {
          this.$store.dispatch("alerts/setMessage", {
            message: "Debes llenar los datos solicitados.",
            type: "warning",
          });
          return;
        }
      }
      if (this.pricePago == 0) {
        this.$store.dispatch("alerts/setMessage", {
          message: "Debes asignar la forma de pago o el efectivo recibido.",
          type: "warning",
        });
        return;
      }
      if (this.cambio < 0) {
        this.$store.dispatch("alerts/setMessage", {
          message: "Falta efectivo por recibir.",
          type: "warning",
        });
        return;
      }
      this.loadingFacturar = true;
      if (!this.flagCF) {
        this.$refs.formClient.resetValidation();
      }
      /*//Para facturar con cualquie establecimiento
      //let data = generateParamsCertificarPDF(this.client, this.detalle);
      let dtee = {
        certificarDocumento: {
          numeroFactura: "1424124141234123412",
          serie: "234",
          folio: "FEL",
          fechaEmision: "2023-08-29T15:30:00",
          fechaCertificado: "2023-08-29T15:30:00",
          uuidTransaccion: "asjdflaksjflas",
          uuidCertificado: "jasdlfasjdflkasdf",
          nombreEmpleado: "chepito",
        },
      };
      this.generateFelPDF(dtee).then(() => {
        this.loadingFacturar = false;
        this.showPrint = true;
        this.go_print();
      });
      //end*/

      let data = generateParamsCertificarPDF(this.client, this.detalle);
      await this.certificarDocumento({ data })
        .then((dtee) => {
          this.generateFelPDF(dtee).then(() => {
            this.loadingFacturar = false;
            this.showPrint = true;
            this.go_print();
          });
        })
        .catch(() => {
          this.loadingFacturar = false;
          this.loadingClient = false;
          this.$store.dispatch("alerts/setMessage", {
            message: "Problemas al certificar la factura",
            type: "warning",
          });
        });
    },
    print() {},
    go_print() {
      this.$router.push({
        name: "ImprimirFactura",
        params: {
          dataNewInvoice: this.dataToPrint,
        },
      });
    },
    // Paginacion
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    getTotalIva(products) {
      let ivaTotal = 0;
      products.forEach((data) => {
        ivaTotal += data.iva;
      });
      return ivaTotal;
    },
    getTempLogo() {
      if (this.chapincitoIds.includes(this.establishment)) {
        return chapincito64;
      }

      if (this.pollolenaIds.includes(this.establishment)) {
        return logoPollo64;
      }

      if (this.establishment === "2533") {
        return logoCafeQuetzalBistro;
      }
      return defaultLogo64;
    },
    async generateFelPDF(dte) {
      //console.log("est");
      //console.log(this.establishment);
      //console.log(typeof this.establishment);
      dte = dte.certificarDocumento;
      let dataItems = await generateBodyFelPDF(this.detalle);
      const data = {
        ...convertIntObj(this.logo),
        ...convertIntObj(this.font),
        color: this.color,
        logo: this.getTempLogo(),
        client: { ...this.client },
        monedaName: "Quetzales",
        usuario: {
          nombre: this.userLoginName,
        },
        establishment: {
          razon: this.establishmentParams.sadm_est_razon,
          telefono: this.establishmentParams.sadm_est_telefono,
          regimen: "Régimen FEL",
          nit: this.establishmentParams.sadm_est_nit,
        },
        factura: {
          vendedor: dte.nombreEmpleado || "",
          tipoDocumento: getTipoDocumentoName("FACT"),
          noFactura: dte.numeroFactura,
          autorizacion: dte.uuidCertificado,
          serie: dte.serie,
          folio: dte.folio,
          fechaEmision: new Date(dte.fechaEmision).toLocaleString(),
          fechaCertificacion: new Date(dte.fechaCertificado).toLocaleString(),
          fechaVencimiento: "08/19/2022 19:13:20",
          Resolucion:
            this.establishmentParams.sadm_est_sat_fecha_resolucion_isr,
          retenedorIva:
            this.establishmentParams.sadm_est_retenedor_iva === "1"
              ? "AGENTE RETENEDOR DE IVA"
              : "",
          textoRegimen: this.establishmentParams.sadm_est_sat_texto_regimen
            ? this.establishmentParams.sadm_est_sat_texto_regimen
            : "",
          Certificador: "MEGAPRINT, S.A. NIT: 5051023-1",
          moneda: "GTQ",
          produccion: true,
          tipoVenta: "Local",
          total: this.total,
          iva: this.getTotalIva(dataItems),
          descuento: 0,
          retenIva: true,
          retenIsr: true,
          aplicaIva: true,
          retencionIsr: 2.23,
          retencionIva: 5.45,
          totalLetras:
            "Un mil novecientos sesenta y seis Quetzales con\n" + "87/100",
          otrosDatos:
            "La receta que os presento hoy no es la clásica que\n" +
            "inventó Doña Plácida de Larrea en mayo de 1723.\n" +
            "Os sorprenderá que tenga fecha exacta… resulta\n" +
            "que hay registro escrito de la receta y de quién la\n" +
            "cocinó. En una carta a una amiga Doña Plácida\n" +
            "comentaba cómo había guisado la merluza, en\n" +
            "salsa con abundantes perejil y adornándola con\n" +
            "espárragos de Tudela, chirlas y una docena de\n" +
            "gordos cangrejos pescados en aguas del Ibaizabal.",
        },
        sucursal: {
          nombre: this.establishmentParams.sadm_est_nombre,
          direccion: this.establishmentParams.sadm_est_direccion,
          direccion_l2: this.establishmentParams.sadm_est_direccion_linea2,
          direccion_l3: this.establishmentParams.sadm_est_direccion_linea3,
          correo: this.establishmentParams.sadm_est_email,
        },
        ffp: {
          ffpFontEncabezado: 7,
          ffpFontProductos: 7,
          ffpInterlineado: 3,
          ffpXTelefono: 0,
          ffpXVendedor: 0,
          ffpXUsuario: 0,
          ffpXTipoPago: 0,
          ffpXCodigoCliente: 0,
          ffpXCodigoClienteAlterno: 0,
          ffpXPrecioUnitario: 0,
          ffpXTituloTotalLetrasMn: 1,
        },
        formatoPdf: "6",
        items: dataItems,
      };
      this.dataToPrint = data;
    },
  },
  mounted() {
    this.selectCategoria();
  },
};
</script>

<style scoped>
.v-list {
  height: 200px;
  overflow-y: auto;
}

#button_list {
  text-align: center;
  line-height: 50px;
  font-size: 1.4rem;
  position: absolute;
  right: 8px;
  top: 170px;
}

.chip_price {
  position: absolute;
  right: 2px;
  top: 2px;
}

.chip_number {
  position: absolute;
  left: 2px;
  top: 2px;
}

.linea-producto_container {
  min-height: 250px;
  max-height: 250px;
}

.categories_container {
  min-height: 170px;
  max-height: 170px;
}
.div-sales {
  min-height: 498px;
  max-height: 498px;
}

.block_search {
  position: relative;
  z-index: 1;
}

.name_item {
  line-height: 1em !important;
}

.btn_det_edit {
  border-radius: 25px 0px 0px 15px;
}

.btn_det_delete {
  border-radius: 25px 0px 0px 15px;
}

.chip_cart {
  position: absolute;
  top: -15px;
  right: 0;
}

.v-expansion-panel-header {
  padding: 16px 12px;
}

.colBorder {
  border: 1px solid #bebebe;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  /* max-height: 100px; */
  max-width: 360px;
  min-width: 260px;
}
</style>
