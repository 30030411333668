import gql from "graphql-tag";

export const getClient = gql`
  query getCliente($nit: String!) {
    getCliente(nit: $nit) {
      codigo
      codigoAlterno
      nit
      nombre
      correo
      direccion
      vendedor
    }
  }
`;
