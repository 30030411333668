<template>
  <div>
    <v-row class="px-0">
      <v-col cols="12" md="12">
        <v-row class="mt-5 mt-md-2">
          <v-col cols="12" class="px-10">
            <h2 class="px-5">Forma de pago</h2>
            <v-row class="px-md-10 pt-5">
              <v-col cols="6">
                <v-btn
                  color="primary"
                  block
                  elevation="0"
                  @click="setFullTotal('efectivo')"
                  :disabled="fullTotal || total > 0"
                >
                  Efectivo
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  block
                  elevation="0"
                  @click="setFullTotal('tarjeta')"
                  :disabled="fullTotal || total > 0"
                >
                  Tarjeta
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-md-16 px-10">
            <v-row>
              <v-col cols="12">
                <h2>Efectivo recibido</h2>
                <v-card flat color="blue-grey lighten-5  mt-3">
                  <div
                    class="headline text-center pt-3"
                    style="min-height: 48px"
                  >
                    {{ formatCurrencyGT(displayResult) }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  flat
                  :class="['lighten-3', fullTotal ? 'grey' : 'primary']"
                >
                  <v-card-text class="pa-0">
                    <v-container grid-list-xs pa-1>
                      <v-layout row wrap pa0>
                        <v-flex v-for="button in buttons" :key="button.key" xs4>
                          <v-btn
                            :class="['ma-0', button.class]"
                            :color="button.color"
                            @click="InputKey(button.key)"
                            block
                            depressed
                            large
                            :disabled="fullTotal"
                          >
                            <v-icon v-if="button.icon" dark>{{
                              button.icon
                            }}</v-icon>
                            <template v-else>{{ button.label }}</template>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <h2>Cambio</h2>
                <v-card flat color="blue lighten-5 mt-3">
                  <div
                    class="headline text-center pt-3"
                    style="min-height: 48px"
                    v-if="total > 0"
                  >
                    {{ formatCurrencyGT(total - totalPago) }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="total > 0">
                <v-btn
                  color="primary"
                  block
                  outlined
                  elevation="0"
                  @click="cleanPayment"
                >
                  limpiar pago</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatCurrencyGT } from "@/core/utils";
export default {
  name: "Calculator",
  props: {
    totalPago: Number,
    detalle: Array,
  },
  data() {
    return {
      buttons: [
        { key: "0.25", label: "0.25", color: "", class: "headline" },
        { key: "0.5", label: "0.5", color: "", class: "headline" },
        { key: "1", label: "1", color: "", class: "headline" },
        { key: "5", label: "5", color: "", class: "headline" },
        { key: "10", label: "10", color: "", class: "headline" },
        { key: "20", label: "20", color: "", class: "headline" },
        { key: "50", label: "50", color: "", class: "headline" },
        { key: "100", label: "100", color: "", class: "headline" },
        { key: "200", label: "200", color: "", class: "headline" },
      ],
      dialog: false,
      value: 0,
      total: 0,
      fullTotal: false,
    };
  },
  methods: {
    cleanPayment() {
      this.total = 0;
      this.fullTotal = false;
    },
    setFullTotal(tipoPago) {
      this.total = this.totalPago;
      this.fullTotal = true;
      this.$emit("handleTipoPago", tipoPago);
    },
    formatCurrencyGT(value) {
      return formatCurrencyGT(value);
    },
    InputKey(key) {
      this.total += Number(key);
    },
  },
  computed: {
    displayResult() {
      return `${this.total.toLocaleString()}`;
    },
    displayCambio() {
      return this.total - this.totalPago;
    },
  },
  watch: {
    displayResult(val) {
      this.$emit("setPricePago", +val || 0);
    },
    displayCambio(val) {
      this.$emit("setCambio", +val || 0);
    },
  },
};
</script>
<style scoped></style>
