import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;
import { getEstablecimiento } from "../../api/establishment";

const state = () => ({
  establishmentParams: [],
  loadingEstablishmenParams: false,
});
// getters
const getters = {};

// actions
const actions = {
  getEstablecimiento({ commit }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getEstablecimiento })
      .then(({ data }) => {
        commit("setEstablishmentParams", data.getEstablecimiento);
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.establishmentParams = loading;
  },
  setEstablishmentParams(state, params) {
    state.loadingEstablishmenParams = false;
    state.establishmentParams = params;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
