import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        navbar: colors.grey.darken4,
      },
      light: {
        navbar: colors.grey.lighten4,
        primary: colors.blue,
        my_yellow: "#e69117",
        support: colors.grey.darken2,
      },
    },
  },
});
