<template>
  <v-card flat>
    <v-card-text>
      <v-row class="pa-5">
        <!--        <v-col cols="12">
          <v-row class="">
            <v-col
              cols="12"
              align="right"
              class="d-inline-flex"
              :class="$vuetify.breakpoint.xs ? 'pb-5' : 'justify-end'"
            >
              <h3 class="primary&#45;&#45;text">
                <v-icon color="my_yellow" class="pr-1">
                  mdi-store-outline</v-icon
                >
                Sucursal:
              </h3>
              <p class="support&#45;&#45;text pl-3">
                {{ emisor.razon }}
              </p>
            </v-col>
          </v-row>
        </v-col>-->
        <v-col cols="12" md="6">
          <h3 class="mb-8 primary--text">
            <v-icon color="my_yellow" class="pr-1">
              mdi-file-document-outline</v-icon
            >
            Factura
          </h3>
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <p class="font-weight-bold">UUID</p>
              <p>{{ invoice.numeroAutorizacion }}</p>
            </v-col>

            <v-col cols="12" md="6" lg="6">
              <p class="font-weight-bold">Número</p>
              <p>{{ invoice.numeroFactura }}</p>
            </v-col>

            <v-col cols="12" md="6" lg="6">
              <p class="font-weight-bold">Fecha Emisión</p>
              <p>{{ invoice.fechaEmision }}</p>
            </v-col>

            <v-col cols="12" md="6" lg="6">
              <p class="font-weight-bold">IVA Incluido</p>
              <p>{{ invoice.ivaIncluido ? "Si" : "No" }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical></v-divider>
        <v-col cols="12" md="6" class="pl-lg-10">
          <h3 class="mb-8 primary--text">
            <v-icon color="my_yellow" class="pr-1">
              mdi-account-box-outline
            </v-icon>
            Cliente
          </h3>
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <p class="font-weight-bold">NIT:</p>
              <p>{{ client.nit }}</p>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <p class="font-weight-bold">Nombre:</p>
              <p>{{ client.nombre }}</p>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <p class="font-weight-bold">Correo:</p>
              <p>{{ client.correo }}</p>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="">
              <p class="font-weight-bold">Total:</p>
              <h3 class="font-weight-bold primary--text pt-2">
                {{ formatCurrencyGT(invoice.total) }}
              </h3>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <h3 class="my-5 primary--text">
            <v-icon color="my_yellow" class="pr-1"> mdi-package-variant</v-icon>
            Productos
          </h3>
          <v-data-table
            :headers="headers"
            :items="products"
            :items-per-page="5"
            :header-props="{ 'sort-by-text': 'Ordenar por' }"
            :footer-props="{
              'items-per-page-text': 'Productos por página',
              'items-per-page-all-text': 'Todos',
            }"
          >
            <template v-slot:[`item.precioUnitario`]="{ item }">
              {{ formatCurrencyGT(item.precioUnitario) }}
            </template>
            <template v-slot:[`item.descuento`]="{ item }">
              {{ formatCurrencyGT(item.descuento) }}
            </template>
            <template v-slot:[`item.subtotal`]="{ item }">
              {{ formatCurrencyGT(item.subtotal) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatCurrencyGT } from "@/core/utils";
export default {
  data() {
    return {
      client: {
        nit: "99642832",
        nombre: "Erik Gerardo Flores Díaz",
        correo: "erflod5@gmail.com",
        direccion: "34 calle 19-71 Zona 12",
      },
      invoice: {
        ivaIncluido: true,
        fechaEmision: "03/03/2022 14:00",
        numeroFactura: "3CF4A201-2763016640",
        numeroAutorizacion: "3CF4A201-2763016640",
        total: 1400.33,
      },
      emisor: {
        razon: "Erik Gerardo Flores Díaz",
      },
      headers: [
        { text: "Código", value: "codigoProducto" },
        { text: "Descripción", value: "descripcion" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio", value: "precioUnitario", align: "right" },
        { text: "Descuento", value: "descuento", align: "right" },
        { text: "SubTotal", value: "subtotal", align: "right" },
      ],
      products: [
        {
          codigoProducto: "100010",
          descripcion: "Servicios",
          cantidad: 2,
          precioUnitario: 700,
          descuento: 0,
          subtotal: 1400.0,
        },
      ],
    };
  },
  methods: {
    formatCurrencyGT(value) {
      return formatCurrencyGT(value);
    },
  },
};
</script>

<style>
.v-application p {
  margin-bottom: -2px !important;
}
</style>
