import QRCode from "qrcode";

const conversor = require("numero-a-letras");
const URLSAT =
  "https://felpub.c.sat.gob.gt/verificador-web/publico/vistas/verificacionDte.jsf";
const ivaExento = [
  { id: 1, name: "Exenta del IVA (art. 7 núm. 2 Ley del IVA)" },
  { id: 2, name: "Exenta del IVA (art. 7 núm. 4 Ley del IVA)" },
  { id: 3, name: "Exenta del IVA (art. 7 núm. 5 Ley del IVA)" },
  { id: 4, name: "Exenta del IVA (art. 7 núm. 9 Ley del IVA)" },
  { id: 5, name: "Exenta del IVA (art. 7 núm. 10 Ley del IVA)" },
  { id: 6, name: "Exenta del IVA (art. 7 núm. 13 Ley del IVA)" },
  { id: 7, name: "Exenta del IVA (art. 7 núm. 14 Ley del IVA)" },
  { id: 8, name: "Exenta del IVA (art. 8 núm. 1 Ley del IVA)" },
  { id: 9, name: "Exenta del IVA (art. 7 núm. 15 Ley del IVA)" },
  { id: 10, name: "Esta factura no incluye IVA (art. 55 Ley del IVA)" },
  { id: 11, name: "No afecta al IVA (Decreto 29-89 Ley de Maquila)" },
  { id: 12, name: "No afecta al IVA (Decreto 65-89 Ley de Zonas Francas)" },
  { id: 13, name: "Exenta del IVA (art. 7 núm. 12, Ley del IVA)" },
  { id: 14, name: "Exenta del IVA (art. 7 núm. 6 Ley del IVA)" },
  { id: 15, name: "Exenta del IVA (art. 7 núm. 11 Ley del IVA)" },
  { id: 16, name: "Exenta del IVA (art. 8 núm. 2 Ley del IVA)" },
  { id: 17, name: "Exenta del IVA (art. 32 literal c Ley Orgánica Zolic)" },
  {
    id: 18,
    name: "(Contribuyentes con resoluciones específicas de exención al IVA)",
  },
];

export async function generateQR(params) {
  const qrBase64 = await QRCode.toDataURL(
    `${URLSAT}?tipo=autorizacion&numero=${params.factura.autorizacion}&emisor=${params.establishment.nit}&receptor=${params.client.nit}&monto=${params.factura.total}`
  );
  let qr = qrBase64.replace("data:image/png;base64,", "");
  if (!params.factura.aplicaIva) {
    let iva = ivaExento[params.factura.escenarioExento - 1];
    params.pdf.text(iva.name);
  }
  return qr;
}

function formatNumber(number) {
  let newNumber = roundNumber(number);
  newNumber = newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return newNumber;
}

function roundNumber(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

function formatNumberCurrency(num) {
  num = parseFloat(num);
  var numWithZeroes = num.toLocaleString("en", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });
  return numWithZeroes;
}

function convertirNumerosALetras(num) {
  let str = conversor.NumerosALetras(num);
  str = str.slice(0, -17);
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export async function convertirTotalALetras(params) {
  let textosTotal = {
    total: "",
    TotalIva: "",
    totalLetras: "",
  };
  if (params.nextY > 200) {
    //createPage(params);
  }
  let sfacImporteIvaF = formatNumber(params.factura.iva, 2);
  params.factura.iva = sfacImporteIvaF;
  let sfacValorTotalF = formatNumberCurrency(params.factura.total);
  let y = params.nextY + 1;
  //let x = 1;
  //params.pdf.font("Helvetica-Bold");
  // /params.pdf.fontSize(params.fontDetalle);
  //params.pdf.text("TOTAL:");
  textosTotal.total = "TOTAL:";
  //params.pdf.text(sfacValorTotalF);
  //params.pdf.font("Helvetica");

  if (
    ["FACT", "FCAM"].includes(params.factura.tipoDocumento) ||
    params.incluyeIva
  ) {
    y += 4.2;
    //params.pdf.text("TOTAL IVA:");
    textosTotal.TotalIva = "TOTAL IVA: " + sfacImporteIvaF;

    //params.pdf.text(sfacImporteIvaF);
    //textosTotal += sfacImporteIvaF;
  }

  if (params.ffp.ffpXTituloTotalLetrasMn > 0) {
    y += 4.2;
    //params.pdf.font("Helvetica-Bold");
    //params.pdf.text("TOTAL EN LETRAS: ");
    textosTotal.totaLenLetras += "TOTAL EN LETRAS: ";
    //params.pdf.font("Helvetica");
  }

  y += 4.2;
  let cfaValorTotal = params.factura.total;
  sfacValorTotalF = params.moneda + " " + formatNumber(cfaValorTotal, 2);

  let moneda = params.monedaName;
  let totalLetras = convertirNumerosALetras(cfaValorTotal);
  if (sfacValorTotalF.substr(sfacValorTotalF.length - 6, 3) === "100") {
    totalLetras = totalLetras.substring(totalLetras.length - 6) + "cien";
  }
  if (sfacValorTotalF.substr(sfacValorTotalF.length - 10, 3) === "100") {
    totalLetras = totalLetras.replace(/Ciento mil/g, "Cien mil");
  }

  totalLetras = totalLetras.replace(/uno/g, "un");

  if (totalLetras === "Un" && moneda === "Quetzales") {
    moneda = "Quetzal";
  }
  let rounded = roundNumber(cfaValorTotal, 2).toFixed(2);
  let decimales = rounded.slice(-2);
  totalLetras = totalLetras + " " + moneda + " con " + decimales + "/100";

  textosTotal.totalLetras = totalLetras;
  y += 7;

  params.nextY = y;
  return textosTotal;
}
