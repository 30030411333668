<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="pa-2">
      <v-card-title class="text-h6 primary--text">
        <v-icon large color="hederColor" class="pr-2">
          mdi-email-fast-outline</v-icon
        >
        Envía tu factura por correo electrónico
      </v-card-title>
      <v-card-text>
        <p class="mt-4 grey--text text--darken-3 font-weight-medium">
          <span class="font-weight-bold">Factura: </span>234523fasdf2345234dsfa
        </p>
        <v-form ref="formMail" v-model="validMail">
          <small class="font-weight-medium">
            <strong>Nota:</strong> Para enviar a múltiples correos, ingresalos
            separados por coma.
          </small>
          <br />
          <v-text-field
            label="Correo"
            hide-details="auto"
            :rules="emailRules"
            outlined
            dense
            class="mt-4"
            v-model="emails"
          ></v-text-field>
          <v-text-field
            label="Asunto"
            hide-details="auto"
            :rules="[(v) => !!v || 'Campo obligatorio']"
            outlined
            dense
            class="mt-4"
            v-model="subject"
          ></v-text-field>
          <v-textarea
            label="Cuerpo"
            hide-details="auto"
            outlined
            dense
            class="mt-4"
            v-model="body"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close" :disabled="loadingMail"
          >volver</v-btn
        >
        <v-btn
          color="primary"
          outlined
          @click="sendMail"
          :loading="loadingMail"
        >
          <v-icon left> mdi-send-outline </v-icon>
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import { enviarFactura } from "../api/file";
export default {
  name: "SendEmail",
  props: {
    emailClient: String,
    autorizacion: String,
  },
  data: () => ({
    dialog: true,
    factura: {},
    loadingMail: false,
    emailRules: [
      (value) => !!value || "llena este campo.",
      (value) =>
        /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
          value
        ) || "Ingresa uno o varios correos válidos",
    ],
    subject: "Envío de factura electrónica.",
    body: "Estimado cliente:\n\nAdjunto encontrará la factura electrónica.",
    emails: "",
    validMail: false,
  }),
  methods: {
    async sendMail() {
      this.factura.uuid = this.autorizacion;
      if (!this.$refs.formMail.validate()) return;
      this.loadingMail = true;
      try {
        const variables = {
          autorizacion: this.factura.uuid,
          body: this.body,
          subject: this.subject,
          email: this.emails,
        };
        await this.$apollo.mutate({
          mutation: enviarFactura,
          variables,
        });
        setTimeout(() => {
          this.alert({
            message: "La factura ha sido enviada.",
            type: "success",
          });
          this.subject = "Envío de factura electrónica.";
          this.body =
            "Estimado cliente:\n\nAdjunto encontrará la factura electrónica.";
          this.emails = "";
          this.$refs.formMail.resetValidation();
          this.dialog = false;
          this.loadingMail = false;
        }, 3000);
      } catch (error) {
        this.loadingMail = false;
        this.alert({
          message: error,
          type: "error",
        });
      }
    },
    close() {
      this.$emit("close");
    },
    ...mapActions({
      alert: "alerts/setMessage",
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    if (this.emailClient) {
      this.emails = this.emailClient;
    }
  },
};
</script>

<style scoped></style>
