<template>
  <v-container>
    <PdfFactura />
  </v-container>
</template>
<script>
import PdfFactura from "../components/pdfFactura";
export default {
  components: { PdfFactura },
};
</script>
