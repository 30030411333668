import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;
import {
  certificarDocumento,
  getFacturas,
  getFactura,
  getFacturaDetalle,
} from "../../api/invoices";

const state = () => ({
  invoices: [],
  invoice: null,
  detalle: [],
  loadingSave: false,
  loading: true,
});

// getters
const getters = {};

// actions
const actions = {
  getFacturas({ commit }, { query }) {
    commit("setLoading", true);
    // commit("setFacturas", []);
    apolloClient
      .query({ query: getFacturas, variables: query })
      .then(({ data }) => {
        commit("setFacturas", data.getFacturas.slice().reverse());
      })
      .catch(() => {
        commit("setLoading", false);
        commit("setFacturas", []);
      });
  },
  getFacturaDetalle({ commit }, { query }) {
    commit("setLoading", true);
    // commit("setFacturas", []);
    apolloClient
      .query({ query: getFacturaDetalle, variables: query })
      .then(({ data }) => {
        commit("setDetalle", data.getFacturaDetalle.slice().reverse());
      })
      .catch(() => {
        commit("setLoading", false);
        commit("setDetalle", []);
      });
  },
  getFactura({ commit }, { query }) {
    commit("setLoading", true);
    // commit("setFacturas", []);
    apolloClient
      .query({ query: getFactura, variables: query })
      .then(({ data }) => {
        commit("setFactura", data.getFactura);
      })
      .catch(() => {
        commit("setLoading", false);
        commit("setFactura", []);
      });
  },

  certificarDocumento({ commit }, { data }) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: certificarDocumento, variables: { data } })
        .then(({ data }) => {
          resolve(data);
          commit("setLoading", false);
        })
        .catch((error) => {
          reject(error);
          commit("setLoading", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setFactura(state, invoice) {
    state.loading = false;
    state.invoice = invoice;
  },
  setFacturas(state, invoices) {
    state.loading = false;
    state.invoices = invoices;
  },
  setDetalle(state, detalle) {
    state.loading = false;
    state.detalle = detalle;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
