import gql from "graphql-tag";

export const getProductos = gql`
  query {
    getProductos {
      sinv_pro_id
      sinv_pro_codigo
      sinv_pro_codigo_alterno
      sinv_pro_descripcion
      sinv_pro_descripcion_pos
      sinv_pro_pos_orden
      sinv_pro_pos_imagen
      sinv_pro_precio
      sinv_ume_siglas
      sinv_lpr_id
      sinv_lpr_descripcion
      sinv_lpr_pos_orden
      sinv_pro_tipo_producto
      sinv_cat_id
      sinv_cat_descripcion
    }
  }
`;
