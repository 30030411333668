import gql from "graphql-tag";

export const getFacturas = gql`
  query getFacturas($fechaInicial: Date!, $fechaFinal: Date!) {
    getFacturas(
      query: { fechaFinal: $fechaFinal, fechaInicial: $fechaInicial }
    ) {
      sfac_cfa_id
      sfac_cfa_numero
      sfac_cfa_valor_total
      sfac_cfa_status
      scrm_cue_nombre
      scrm_cue_nit
      sfac_cfa_face_fecha_emision
      sfac_cfa_fecha
    }
  }
`;

export const getFacturaDetalle = gql`
  query getFacturaDetalle($cfaId: Int!) {
    getFacturaDetalle(query: { cfaId: $cfaId }) {
      sinv_pro_id
      sinv_pro_codigo
      sfac_fac_cantidad
      sfac_fac_cantidad_enviada
      sinv_pro_descripcion
      sinv_pro_precio
      sfac_fac_importe_bruto
      sfac_fac_importe_descuento
      sfac_fac_importe_iva
      sinv_pro_serie
      sinv_ume_id
      sinv_ume_siglas
    }
  }
`;

export const getFactura = gql`
  query getFactura($cfaId: Int!) {
    getFactura(query: { cfaId: $cfaId }) {
      sfac_cfa_id
      sfac_cfa_face_firma_electronica
      sfac_cfa_numero
      sfac_cfa_valor_total
      sfac_cfa_fecha
      sfac_cfa_status
      scrm_cue_nombre
      scrm_cue_nit
      scrm_cue_codigo
      scrm_cue_email
      scrm_cue_factura_direccion
      sfac_cfa_face_serie
      sfac_cfa_face_folio
      sfac_cfa_face_fecha_emision
      sfac_cfa_face_fecha_certificado
      sfac_cfa_face_firma_electronica
    }
  }
`;

export const certificarDocumento = gql`
  mutation certificarDocumento($data: CertificarDocumentoInput!) {
    certificarDocumento(input: $data) {
      numeroFactura
      serie
      folio
      fechaEmision
      fechaCertificado
      uuidTransaccion
      uuidCertificado
      nombreEmpleado
    }
  }
`;

export const sendEmailDte = gql`
  mutation sendEmailDte(
    $autorizacion: String!
    $body: String!
    $subject: String!
    $email: String!
  ) {
    sendEmailDte(
      autorizacion: $autorizacion
      body: $body
      subject: $subject
      email: $email
    )
  }
`;
