import { base64 } from "./base64";
import axios from "axios";
/**
 * Converts PublicKeyCredential into serialised JSON
 * @param  {Object} pubKeyCred
 * @return {Object}            - JSON encoded publicKeyCredential
 */

export function convertIntObj(obj) {
  const res = {};
  for (const key in obj) {
    const parsed = parseInt(obj[key], 10);
    res[key] = isNaN(parsed) ? obj[key] : parsed;
  }
  return res;
}

const publicKeyCredentialToJSON = (pubKeyCred) => {
  if (pubKeyCred instanceof Array) {
    let arr = [];
    for (let i of pubKeyCred) arr.push(publicKeyCredentialToJSON(i));

    return arr;
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    return base64.encode(pubKeyCred);
  }

  if (pubKeyCred instanceof Object) {
    let obj = {};

    for (let key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }

    return obj;
  }

  return pubKeyCred;
};

/**
 * Generate secure random buffer
 * @param  {Number} len - Length of the buffer (default 32 bytes)
 * @return {Uint8Array} - random string
 */
const generateRandomBuffer = (len) => {
  len = len || 32;

  let randomBuffer = new Uint8Array(len);
  window.crypto.getRandomValues(randomBuffer);

  return randomBuffer;
};

/**
 * Decodes arrayBuffer required fields.
 */
const preformatMakeCredReq = (makeCredReq) => {
  makeCredReq.challenge = base64.decode(makeCredReq.challenge);
  makeCredReq.user.id = base64.decode(makeCredReq.user.id);

  return makeCredReq;
};

/**
 * Decodes arrayBuffer required fields.
 */
const preformatGetAssertReq = (getAssert) => {
  getAssert.challenge = base64.decode(getAssert.challenge);

  for (let allowCred of getAssert.allowCredentials) {
    allowCred.id = base64.decode(allowCred.id);
  }

  return getAssert;
};

const downloadPDF = (url, filename) => {
  fetch(url, {
    method: "GET",
  })
    .then((response) => response.blob())
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });

const toBase64WithHeader = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const detectBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") != -1 ||
    !!document.documentMode == true
  ) {
    return "IE";
  } else {
    return "Unknown";
  }
};

const downloadFile = (url, label, type) => {
  if (["Safari", "Unknown"].includes(detectBrowser())) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.click();
    document.body.removeChild(a);
    return;
  }
  axios
    .get(url, { responseType: "blob" })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: type === "pdf" ? "application/pdf" : "text/xml",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = label + "." + type;
      anchor.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(console.error);
};

const getPlantillaNameById = (paramSearch) => {
  const plantillas = {
    1: "Plantilla Profesional",
    2: "Plantilla Minimalista",
    3: "Plantilla Geométrica",
    4: "Plantilla Médica",
    5: "Plantilla Empresarial",
    6: "Plantilla Ticket",
  };
  return plantillas[paramSearch];
};

const getTipoDocumentoName = (type) => {
  const items = {
    FACT: "Factura",
    FCAM: "Factura Cambiaria",
    FPEQ: "Factura Pequeño Contribuyente",
    FCAP: "Factura Cambiaria Pequeño Contribuyente",
    RDON: "Recibo por Donación",
    NCRE: "Nota de Crédito Descuento",
    NDEB: "Nota de Débito",
    NABN: "Nota de Abono",
    FESP: "Factura Especial",
    RECI: "Recibo",
    FACA: "Factura Contribuyente Agropecuario",
    FCCA: "Factura Cambiaria Contribuyente Agropecuario",
    FAPE: "Factura Pequeño Contribuyente Régimen Electrónico",
    FCPE: "Factura Cambiaria Pequeño Contribuyente Régimen Electrónico",
    FAAE: "Factura Contribuyente Agropecuario Régimen Electrónico Especial",
    FCAE: "Factura Cambiaria Contribuyente Agropecuario Régimen Electrónico Especial",
  };
  return items[type] || "Factura";
};

export {
  publicKeyCredentialToJSON,
  generateRandomBuffer,
  preformatMakeCredReq,
  preformatGetAssertReq,
  downloadPDF,
  toBase64,
  toBase64WithHeader,
  dataURLtoFile,
  detectBrowser,
  downloadFile,
  getPlantillaNameById,
  getTipoDocumentoName,
};
