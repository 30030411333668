import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config.js";
import alerts from "./modules/alerts.js";
import products from "./modules/products.js";
import invoices from "./modules/invoices.js";
import categories from "../api/categories";
import establishment from "./modules/establishment";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config,
    alerts,
    products,
    invoices,
    categories,
    establishment,
  },
});
