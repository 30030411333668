import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;
import { getProductos } from "../../api/products";

const state = () => ({
  products: [],
  product: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  getProductos({ commit, dispatch }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getProductos })
      .then(({ data }) => {
        if (data.getProductos.length > 0) {
          let productos = data.getProductos;
          let productosConOrden = productos
            .slice()
            .filter((producto) => producto.sinv_pro_pos_orden !== null)
            .sort((a, b) => {
              return a.sinv_pro_pos_orden - b.sinv_pro_pos_orden;
            });
          let productosSinOrden = productos
            .slice()
            .filter((producto) => producto.sinv_pro_pos_orden === null)
            .sort((a, b) => {
              return a.sinv_pro_id - b.sinv_pro_id;
            });

          productos = [].concat(productosConOrden, productosSinOrden);
          commit("setProductos", productos);

          dispatch("categories/getLineasProducto", productos, {
            root: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setProductos(state, products) {
    state.loading = false;
    state.products = products;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
